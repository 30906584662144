import { awsApi } from "../aws/awsApi";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { chargesApi } from "../charges/chargesApi";
import { ticketsApi } from "../tickets/ticketsApi";
import { productsApi } from "../products/productsApi";
import { servicesApi } from "../services/servicesApi";
import { terminalsApi } from "../terminals/terminalsApi";
import { locationsApi } from "../terminals/locationsApi";
import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { cleanLocalEntries, LocalEntry, selectLocalEntries } from "../timeEntries/localEntriesSlice";
import { DraftBasicValues, DraftConsigneeValues, DraftConsignorValues, DraftExtraValues, DraftLocationsValues, DroppOffValues } from "../tickets/tickets";
import { coordinatesApi } from "../terminals/coordinatesApi";
export interface DraftProductsValues {
  product: ExistingOrNew
  subsidiary_class?: string
  toxic_by_inhalation?: boolean
  quantity?: number
  packages?: number
}
export interface DraftProductPayload {
  id?: number
  updateDraftProducts: DraftProductsValues[]
}
export interface DraftServicesValues {
  record_id?: number;
  service: number;
  rate?: string | null;
  description?: string | null;
  quantity?: string | null

}
export interface DraftServicesPayload {
  id?: number
  updateDraftServices: DraftServicesValues[]
}
export interface DraftChargeValues {
  description: string
  quantity: string
  unit: string
  rate: number
  allow_surcharge: boolean
  amount: number
}

export interface DraftStampDetails {
  id?: number
  cost_class: number | null,
  minor_class: number | null,
  major_class: number | null,
  other: null,
  digital_sign: string | null
}
export interface Draft {
  number?: number | null
  hasContent?: boolean
  basic: DraftBasicValues | null
  locations?: DraftLocationsValues | null
  consignor?: DraftConsignorValues | null
  consignee?: DraftConsigneeValues | null
  products: DraftProductsValues[]
  services: DraftServicesValues[]
  pickUp: DraftExtraValues | null
  droppOff: DroppOffValues | null
  attachments: string[] | null
  charges?: DraftChargeValues[] | null
  driver_lead_add_charges: boolean
  stampDetails: DraftStampDetails
}
interface DraftState {
  drafts: Draft[]
}
const initialState: DraftState = {
  drafts: [],
}

const draftSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    discardDraft(state, action) {
      const { id } = action.payload
      const updatedDrafts = [...state.drafts] // Create a copy of the drafts array
      updatedDrafts.splice(id, 1) // Remove the draft at draftIndex from the copied array
      return {
        ...state,
        drafts: updatedDrafts, // Update the drafts array in the state with the modified copy
      }
    },
    discardAllDrafts(state) {
      return {
        ...state,
        drafts: [],
      }
    },
    saveDraftBasic(state, action: PayloadAction<DraftBasicValues>) {
      const { id, ...data } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          basic: data,
        }
      }
    },
    saveDraftLocations(state, action: PayloadAction<DraftLocationsValues>) {
      const { id, ...data } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          locations: data,
        }
      }
    },
    saveDraftProducts(state, action: PayloadAction<DraftProductPayload>) {
      const { id, updateDraftProducts } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          products: updateDraftProducts,
        }
      }
    },
    saveDraftServices(state, action: PayloadAction<DraftServicesPayload>) {
      const { id, updateDraftServices } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          services: updateDraftServices,
        }
      }
    },
    saveDraftPickUp(state, action: PayloadAction<DraftExtraValues>) {
      const { id, ...data } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          pickUp: data,
        }
      }
    },
    saveDraftDroppOff(state, action: PayloadAction<DroppOffValues>) {
      const { id, ...data } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          droppOff: data,
        }
      }
    },
    saveDraftStampDetails(state, action: PayloadAction<DraftStampDetails>) {
      const { id, ...data } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          stampDetails: data,
        }
      }
    },
    saveDraftAttachments(state, action) {
      const { id, attachments } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          attachments: attachments,
        }
      }
    },
    saveDraftCharge(state, action) {
      const { id, updatedCharges } = action.payload

      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          charges: updatedCharges
        }
      }
    },
    saveAskToDriveLead(state, action) {
      const { id, driver_lead_add_charges } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          driver_lead_add_charges: driver_lead_add_charges
        }
      }
    },
    bindDraftNumber(state, action) {
      const { id, draftuniqueid } = action.payload
      if (id != null) {
        state.drafts[id] = {
          ...state.drafts[id],
          hasContent: true,
          number: draftuniqueid,
        }
      }
    },
  },
})

export const selectDraft = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]

export const selectDraftNumber = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.number

export const selectDraftHasContent = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.hasContent || false

export const selectDraftBasic = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.basic || {
    reference_date: new Date(),
    consignor_contact: null,
    consignor_phone: null,
    billing_email: null,
    emergency_contact: null,
    equipments: [null],
    customer: null
  }
);

export const selectDraftLocations = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.locations || {
    consignor: {
      terminal: "",
      location: "",
      lsdprefix: "",
      lsd: "",
    },
    consignee: {
      terminal: "",
      location: "",
      lsdprefix: "",
      lsd: "",
    },
  }
);

export const selectDraftProducts = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.products || []
);

export const selectDraftServices = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.services || []
);

export const selectPickUp = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.pickUp || {
    estimated_volume: '',
    // sequence: '',
    // sw_percent: '',
    tank_gauge_details: [
      {
        start: '',
        finish: '',
      },
    ],
    confirmed_empty: false,
    residue_last_contained: '',
  }
);

export const selectDroppOff = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => draft?.droppOff || {
    actual_volume: '',
    work_description: '',
    sequence: '',
    sw_percent: '',
  }
);

export const selectDraftTimeEntries = (id: number) => createSelector(
  (state: RootState) => state?.drafts?.drafts[id],
  (draft) => {
    const localEntries = useAppSelector(selectLocalEntries)
    return localEntries.filter(
      (item: LocalEntry) => item.ticketDraft === draft?.number,
    )
  }
)

export const selectDraftCharges = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.charges

export const selectDraftStampDetails = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.stampDetails

export const createTicketAfterDraft = createAsyncThunk(
  "draft/createTicketAfterDraft",
  async (id: number, { dispatch, getState, rejectWithValue }) => {
    try {
      const draftState: Draft = (getState() as RootState).drafts.drafts[id]
      const profile_id = (getState() as RootState).session.resource?.profile?.id
      const { terminals, locations, customers, coordinates } = getState() as RootState;

      const customerId =
        // typeof draftState?.basic?.customer === "string"
        //   ? (
        //     await dispatch(
        //       customersApi.endpoints.createCustomer.initiate({
        //         name: draftState?.basic?.customer,
        //       }),
        //     ).unwrap()
        //   ).id
        //   : 
        draftState?.basic?.customer

      const localTimeEntriesState = (getState() as RootState).localEntries

      //filter local entries bind to this draft number
      const finalEntries = localTimeEntriesState.entries.filter(
        (item) => item.ticketDraft === draftState?.number,
      )
      const finalLocalEntries = finalEntries.map((item) => {
        return {
          worker: item.worker,
          activity: item.activity,
          start: item.start,
          finish: item.finish,
        }
      })
      let consignorTerminalId: number | undefined;
      let consignorLocationId: number | undefined;

      const consignorTerminal = draftState?.locations?.consignor?.terminal || ''
      const consignorLocation = draftState?.locations?.consignor?.location || ''
      const consignorLsd = draftState?.locations?.consignor?.lsd || '';
      const consigneeLsd = draftState?.locations?.consignee?.lsd || '';

      const getTerminalLSDs = coordinates?.ids || [];

      const createLSD = async (lsd: string, terminalId: number) => {
        const response = await dispatch(coordinatesApi.endpoints.createCoordinates.initiate({ lsd, terminal_id: terminalId, })).unwrap();
        return response.id;
      };

      try {
        const getLocationEntity = (id: number) => locations?.entities?.[id];
        const getTerminalLocations = (id: number) => terminals?.entities?.[id]?.locations;
        const createTerminal = async (name: string) => {
          const response = await dispatch(terminalsApi.endpoints.createTerminal.initiate({ name, for: "consignor" })).unwrap();
          return response.id;
        };

        const createLocation = async (name: string, terminalId: number | undefined) => {
          const response = await dispatch(locationsApi.endpoints.createLocation.initiate({ name, terminal_id: terminalId, for: "consignor" })).unwrap();
          return response.id;
        };

        const isNonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';
        if (typeof consignorTerminal === 'number' && typeof consignorLocation === 'number') {
          const currentTerminalLocations = getTerminalLocations(consignorTerminal);
          const isExist = currentTerminalLocations?.some((item) => item.id === consignorLocation);
          if (!isExist) {
            const currentLocation = getLocationEntity(consignorLocation);
            if (!currentLocation || !currentLocation.name) {
              throw new Error("Current location or location name is invalid");
            }
            consignorLocationId = await createLocation(currentLocation?.name, consignorTerminal);
          } else {
            consignorLocationId = consignorLocation;
          }
          consignorTerminalId = consignorTerminal;
        } else if (isNonEmptyString(consignorTerminal) && typeof consignorLocation === 'number') {
          const currentLocation = getLocationEntity(consignorLocation);
          if (!currentLocation || !currentLocation.name) {
            throw new Error("Current location or location name is invalid");
          }
          consignorTerminalId = await createTerminal(consignorTerminal.toString());
          consignorLocationId = await createLocation(currentLocation?.name, consignorTerminalId);
        } else if (isNonEmptyString(consignorTerminal) && isNonEmptyString(consignorLocation)) {
          consignorTerminalId = await createTerminal(consignorTerminal.toString());
          consignorLocationId = await createLocation(consignorLocation.toString(), consignorTerminalId);
        } else if (typeof consignorTerminal === 'number' && typeof isNonEmptyString(consignorLocation)) {
          consignorLocationId = await createLocation(consignorLocation.toString(), consignorTerminal);
          consignorTerminalId = consignorTerminal;
        }

        // const isConsignorLsdExists = getTerminalLSDs.includes(consignorLsd);

        // if (!isConsignorLsdExists && consignorLsd != '') {
        //   createLSD(consignorLsd, consignorTerminal as number)
        // }

      } catch (error) {
        console.error("Error from ticket consignor:", error);
        throw error;
      }

      const isConsignorLsdExists = getTerminalLSDs.includes(consignorLsd);

      if (!isConsignorLsdExists && consignorLsd != '') {
        createLSD(consignorLsd, consignorTerminalId as number)
      }

      let consigneeTerminalId: number | undefined;
      let consigneeLocationId: number | undefined;
      const consigneeTerminal = draftState?.locations?.consignee?.terminal || ''
      const consigneeLocation = draftState?.locations?.consignee?.location || ''



      try {
        const getLocationEntity = (id: number) => locations?.entities?.[id];
        const getTerminalLocations = (id: number) => terminals?.entities?.[id]?.locations;

        const createTerminal = async (name: string) => {
          const response = await dispatch(terminalsApi.endpoints.createTerminal.initiate({ name, for: "consignee" })).unwrap();
          return response.id;
        };
        const createLocation = async (name: string, terminalId: number) => {
          const response = await dispatch(locationsApi.endpoints.createLocation.initiate({ name, terminal_id: terminalId, for: "consignee" })).unwrap();
          return response.id;
        };
        const isNonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';
        if (typeof consigneeTerminal === 'number' && typeof consigneeLocation === 'number') {
          const currentTerminalLocations = getTerminalLocations(consigneeTerminal);
          const isExist = currentTerminalLocations?.some((item) => item.id === consigneeLocation);

          if (!isExist) {
            const currentLocation = getLocationEntity(consigneeLocation);
            if (!currentLocation || !currentLocation.name) {
              throw new Error("Current location or location name is invalid");
            }
            consigneeLocationId = await createLocation(currentLocation?.name, consigneeTerminal);
          } else {
            consigneeLocationId = consigneeLocation;
          }
          consigneeTerminalId = consigneeTerminal;
        } else if (isNonEmptyString(consigneeTerminal) && typeof consigneeLocation === 'number') {
          const currentLocation = getLocationEntity(consigneeLocation);
          if (!currentLocation || !currentLocation.name) {
            throw new Error("Current location or location name is invalid");
          }
          consigneeTerminalId = await createTerminal(consigneeTerminal.toString());
          consigneeLocationId = await createLocation(currentLocation?.name, consigneeTerminalId);
        } else if (isNonEmptyString(consigneeTerminal) && isNonEmptyString(consigneeLocation)) {
          consigneeTerminalId = await createTerminal(consigneeTerminal.toString());
          consigneeLocationId = await createLocation(consigneeLocation.toString(), consigneeTerminalId);
        } else if (typeof consigneeTerminal === 'number' && isNonEmptyString(consigneeLocation)) {
          consigneeLocationId = await createLocation(consigneeLocation.toString(), consigneeTerminal);
          consigneeTerminalId = consigneeTerminal;
        }

      } catch (error) {
        console.error("Error from ticket consignee:", error);
        throw error;
      }
      const isConsigneeLsdExists = getTerminalLSDs.includes(consigneeLsd);

      if (!isConsigneeLsdExists && consigneeLsd != '') {
        createLSD(consigneeLsd, consigneeTerminalId as number)
      }

      const equipmentDetails = draftState?.basic?.equipments.map(
        (equipment) => ({
          equipment_id: equipment,
        }),
      )

      const productDetails = draftState?.products
        ? await Promise.all(
          draftState?.products.map(async (item) => {
            const productId =
              typeof item.product === "string"
                ? (
                  await dispatch(
                    productsApi.endpoints.createProduct.initiate({
                      name: item.product,
                    }),
                  ).unwrap()
                ).id
                : item.product

            return {
              product_id: productId,
              toxic_by_inhalation: item.toxic_by_inhalation,
              subsidiary_class: item.subsidiary_class,
              quantity: item.quantity,
              packages: item.packages,
            }
          }),
        )
        : []

      const serviceDetails = draftState?.services
        ? await Promise.all(
          draftState?.services.map(async (item) => {
            const serviceId =
              typeof item.service === "string"
                ? (
                  await dispatch(
                    servicesApi.endpoints.createService.initiate({
                      name: item.service,
                    }),
                  ).unwrap()
                ).id
                : item.service

            return {
              service_id: serviceId,
              quantity: item.quantity,
              rate: item.rate,
              description: item.description,
            }
          }),
        )
        : []

      const lastContainedProductId =
        typeof draftState?.pickUp?.residue_last_contained === "string" &&
          draftState?.pickUp?.residue_last_contained.trim().length > 0
          ? (
            await dispatch(
              productsApi.endpoints.createProduct.initiate({
                name: draftState?.pickUp?.residue_last_contained,
              }),
            ).unwrap()
          ).id
          : draftState?.pickUp?.residue_last_contained

      const sign = draftState?.stampDetails?.digital_sign
      const digital_signature = sign
        ? await (async () => {
          try {
            const blob = await (await fetch(sign)).blob();
            const contentType = blob.type;
            const fileObjects = {
              id: 1,
              object_key: `${draftState?.number}/signature/${draftState?.number}.png`,
              mime_type: contentType,
            };
            const presignedUrlResponse = await dispatch(
              awsApi.endpoints.getPresignedUrl.initiate({ object_keys: [fileObjects] })
            );
            if (!('data' in presignedUrlResponse)) throw new Error("Failed to get presigned URL");
            const { url, object_key: file_key } = presignedUrlResponse.data?.[0];
            const file = { url, file: blob };
            const uploadResponse = await dispatch(awsApi.endpoints.uploadFileToS3.initiate(file));
            if (!uploadResponse) throw new Error("Failed to upload file to S3");
            return file_key;
          } catch (error) {
            console.error("Error during digital signature process:", error);
            return null;
          }
        })()
        : null;

      // //customer contact 
      // const consignorName = draftState?.basic?.consignor_contact;
      // const consignorPhone = draftState?.basic?.consignor_phone;
      // const billing_email = draftState?.basic?.billing_email;

      // let consignorContactId: number | undefined;

      // try {
      //   if (customerId && (consignorName || consignorPhone || billing_email)) {
      //     if (typeof consignorName === 'string') {
      //       const payload = {
      //         customer: customerId,
      //         contact_phone: consignorPhone,
      //         contact_name: consignorName,
      //         contact_email: billing_email
      //       };

      //       const res = await dispatch(
      //         customerContactsApi.endpoints.createContact.initiate(payload),
      //       ).unwrap()
      //       consignorContactId = res?.id
      //     } else {
      //       const contactList = customers?.entities?.[customerId]?.contacts
      //       const exist = contactList?.find((item) => item.id === consignorName)
      //       const payload = {
      //         id: consignorName,
      //         contact_phone: typeof consignorPhone === 'number' ? exist?.contact_phone : consignorPhone,
      //         contact_email: typeof billing_email === 'number' ? exist?.contact_email : billing_email
      //       };
      //       const res = await dispatch(
      //         customerContactsApi.endpoints.updateContact.initiate(payload),
      //       ).unwrap()
      //       consignorContactId = res?.id
      //     }
      //   }
      // } catch (error) {
      //   console.error("Error from ticket consignee:", error);
      //   throw error;
      // }

      const ticketPayload = {
        driver_id: profile_id,
        status: "submitted",
        reference_date: draftState?.basic?.reference_date,
        customer_id: customerId,
        number: draftState?.number,
        consignor_terminal_id: consignorTerminalId,
        consignor_location_id: consignorLocationId,
        consignor_lsdprefix: draftState?.locations?.consignor?.lsdprefix,
        consignor_lsd: draftState?.locations?.consignor?.lsd,
        consignee_terminal_id: consigneeTerminalId,
        consignee_location_id: consigneeLocationId,
        consignee_lsdprefix: draftState?.locations?.consignee?.lsdprefix,
        consignee_lsd: draftState?.locations?.consignee?.lsd,
        emergency_contact: draftState?.basic?.emergency_contact,
        // customer_contact_id: draftState?.basic?.consignor_contact,
        // customer_billing_id: draftState?.basic?.billing_email,
        confirmed_empty: draftState?.pickUp?.confirmed_empty,
        residue_last_contained: lastContainedProductId,
        estimated_volume: draftState?.pickUp?.estimated_volume,
        tank_gauge_details: draftState?.pickUp?.tank_gauge_details,
        actual_volume: draftState?.droppOff?.actual_volume,
        sequence: draftState?.droppOff?.sequence,
        sw_percent: draftState?.droppOff?.sw_percent,
        work_description: draftState?.droppOff?.work_description,
        equipmentdetails: equipmentDetails,
        productdetails: productDetails,
        servicedetails: serviceDetails,
        attachments: draftState?.attachments,
        driver_lead_add_charges: draftState?.driver_lead_add_charges || false,
        timeentries: finalLocalEntries,
        digital_signed: digital_signature ? true : false,
        signature: digital_signature,
        cost_class: draftState?.stampDetails?.cost_class,
        minor_class: draftState?.stampDetails?.minor_class,
        major_class: draftState?.stampDetails?.major_class,
        other: draftState?.stampDetails?.other,
      }

      // check consignor contact is new ?
      const finalTicketPayload = {
        ...ticketPayload,
        ...(draftState?.basic?.consignor_contact ?
          typeof draftState?.basic?.consignor_contact === 'number'
            ? { customer_contact_id: draftState?.basic?.consignor_contact }
            : { new_consignor_contact: draftState?.basic?.consignor_contact }
          : {}
        )
      }
      const newTicket = await dispatch(
        ticketsApi.endpoints.createTicket.initiate(finalTicketPayload),
      ).unwrap()

      console.log("New Ticket", newTicket)

      //bind charges 
      draftState?.charges &&
        await Promise.all(
          draftState?.charges.map(async (charge) => {
            const payload = {
              description: charge.description,
              unit: charge.unit,
              rate: charge.rate,
              quantity: charge.quantity,
              amount: charge.amount,
              ticket: newTicket?.id,
              allow_surcharge: charge.allow_surcharge ?? false,
            }
            await dispatch(
              chargesApi.endpoints.createCharge.initiate(payload),
            )
          })
        )
      dispatch(discardDraft({ id: id }))
      dispatch(cleanLocalEntries({ draftNumber: draftState?.number ?? null }))
      return newTicket
    } catch (error) {
      console.error("Failed to create ticket:", error)
      return rejectWithValue(error)
    }
  },
)

export const {
  discardDraft,
  discardAllDrafts,
  saveDraftBasic,
  saveDraftLocations,
  saveDraftProducts,
  saveDraftServices,
  saveDraftPickUp,
  saveDraftDroppOff,
  saveDraftAttachments,
  saveDraftStampDetails,
  saveDraftCharge,
  saveAskToDriveLead,
  bindDraftNumber,
} = draftSlice.actions

export default draftSlice.reducer
