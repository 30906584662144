import { useAppSelector } from "../../../app/hooks";
import { selectTerminalById } from "../../../features/terminals/terminalsSlice";
import { FieldProps } from "../../../forms";
import { LsdAutocompleteField, AutocompleteSuggestion } from "./LsdAutocompleteField";

interface LsdAutocompleteProps extends FieldProps {
  terminalId?: ExistingOrNew
  placeholder?: string
  isDisabled?: boolean
}


export function LsdAutocomplete({
  label,
  name,
  terminalId,
  helper,
  registerOptions,
  placeholder,
  isDisabled,
}: LsdAutocompleteProps) {
  const terminal = useAppSelector((state) =>
    selectTerminalById(state, terminalId || 0),
  )

  const lsds = terminal?.lsds || []
  const suggestions = lsds.map((lsd) => ({
    value: lsd.lsd || '',
    text: lsd.lsd || "unknown",
  }))


  const handleMatch = (suggestion: AutocompleteSuggestion) => {
    console.log("Selected", suggestion)
  }

  return (
    <LsdAutocompleteField
      label={label}
      name={name}
      suggestions={suggestions}
      onMatch={handleMatch}
      helper={helper}
      registerOptions={registerOptions}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  )
}
