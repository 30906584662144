import { useState } from 'react';
import { selectUserById } from '../users/usersSlice';
import { ModalForm } from '../../components/forms/ModalForm';
import { TimeEntryForm } from '../timeEntries/TimeEntryForm';
import { LocalEntry } from '../timeEntries/localEntriesSlice';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { InputStreak } from '../../components/forms/InputStreak';
import { selectActivityById } from '../activities/activitySlice';
import { CustomButton } from '../../components/forms/CustomButton';
import { WrapperProps } from '../../components/TicketDetailWrapper';
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField';
import { calculateDeltaMinutes, formatMinutesAsHours } from '../../app/utils';
import { Box, HStack, IconButton, StackDivider, Text, useDisclosure, VStack } from '@chakra-ui/react';

interface DriverHoursForm_NEWProps extends WrapperProps {
    timeEntries: LocalEntry[]
    onSave(data: any, index: number | null): void
    onRemove(index: number): void
    onEdit?(entry: LocalEntry): void
    isdraft?: boolean
}

function DriverHoursForm({
    onSave,
    onRemove,
    isdraft,
    timeEntries
}: DriverHoursForm_NEWProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedEntry, setSelectedEntry] = useState<LocalEntry | null>(null);
    const [selectedEntryIndex, setSelectedEntryIndex] = useState<number | null>(null);
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');

    const handleModalOpen = (mode: 'add' | 'edit', entry?: LocalEntry, index?: number) => {
        setModalMode(mode);
        setSelectedEntryIndex(index ?? null)
        setSelectedEntry(entry ?? null);
        onOpen();
    };
    const handleSave = (data: any) => {
        onSave?.(data, selectedEntryIndex)
    }
    return (
        <>
            <VStack w="full" alignItems="flex-start" gap={4} p={[0, 2, 4]} divider={<StackDivider />} >
                {
                    timeEntries?.length > 0 ?
                        timeEntries.map((item, index) => (
                            <HStack w="full" alignItems="flex-end" >
                                <InputStreak>
                                    <ReadonlyField
                                        label="Worker"
                                        traitId={Number(item.worker)}
                                        traitGetter={selectUserById}
                                    />
                                    <ReadonlyField
                                        label="Activity"
                                        traitId={Number(item.activity)}
                                        traitGetter={selectActivityById}
                                    />
                                    <ReadonlyField
                                        label="Total Hours"
                                        value={formatMinutesAsHours(calculateDeltaMinutes(item.start, item.finish))}
                                    />
                                </InputStreak>
                                <HStack>
                                    {(!item?.is_approved) &&
                                        <IconButton
                                            aria-label="Edit"
                                            icon={<KnownIcon name="edit" boxSize="24px" cursor="pointer" />}
                                            onClick={() => handleModalOpen('edit', item, index)}
                                            backgroundColor="transparent"
                                            _hover={{ backgroundColor: "transparent" }}
                                        />
                                    }
                                    {
                                        isdraft &&
                                        <IconButton
                                            aria-label="Remove"
                                            icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                            onClick={() => { onRemove(index) }}
                                            backgroundColor="transparent"
                                            _hover={{ backgroundColor: "transparent" }}
                                        />
                                    }
                                </HStack>
                            </HStack>
                        ))
                        : (
                            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={3}>
                                Add Hours
                            </Box>
                        )}
                <Box>
                    {timeEntries.length > 0 && (
                        <HStack mb={5}>
                            <Text fontSize="14px" color="#455360">Grand Total Hours:</Text>
                            <Text fontWeight="bold">
                                {formatMinutesAsHours(
                                    timeEntries.reduce((total, entry) =>
                                        total + calculateDeltaMinutes(entry.start, entry.finish), 0
                                    )
                                )}
                            </Text>
                        </HStack>
                    )}
                    <CustomButton
                        title="Add New Entry"
                        variant='outline'
                        onClick={() => handleModalOpen('add')}
                    />
                </Box>
            </VStack>

            <ModalForm
                isdraft={isdraft}
                title={modalMode === 'add' ? 'Add New Entry' : 'Edit Entry'}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={handleSave}
                defaultValues={selectedEntry}
            >
                <TimeEntryForm
                    isEdit={modalMode === 'edit' ? true : false}
                />
            </ModalForm>
        </>
    )
}

export default DriverHoursForm