import { PuffLoader } from "react-spinners";
import { useEffect, useMemo, useState } from "react";
import { Container } from "../../components/Container";
import { ErrorBox } from "../../components/ErrorBox";
import { FilterTile } from "../../components/FilterTile";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { OverlayLoader } from "../../components/OverlayLoader";
import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import { TimeEntriesResource } from "./ApproveSubmittedTimeEntries";
import { pageSizeOptions } from "../../features/tickets/ticket_choices";
import { TrackingPageHeader } from "../../components/TrackingPageHeader";
import { TimeEntryCard } from "../../features/timeEntries/TimeEntryCard";
import { FilterValues, OptionType } from "../../features/tickets/tickets";
import { TimeEntryPanel } from "../../features/timeEntries/TimeEntryPanel";
import { PaginatedListLayout } from "../../layout/areas/PaginatedListLayout";
import { useListActivitiesQuery } from "../../features/activities/activitiesApi";
import { useAppDispatch, useAppSelector, useConfigCheck } from "../../app/hooks";
import { clearPageFilters, savePageFilters } from "../../features/tickets/filterSlice";
import { getDateString, hasSubmittedTimeListActiveFilters, hasTimeTrackingListActiveFilters } from "../../app/utils";
import { useDestroyTimeEntryMutation, useDriverTimeEntryQuery, usePartialUpdateTimeEntryMutation } from "../../features/timeEntries/timeEntriesApi";

export type DateRange = [Date | null, Date | null];
export interface TimeEntryDetail {
  date: string,
  total_hours: number,
  entries: TimeEntriesResource[]
}

const orderingMap: Record<number, string> = {
  0: "date", //ascending order
  1: "-date" //descending order
};

export function TrackingPage() {
  useListActivitiesQuery(); // fetch activities on page load
  const dispatch = useAppDispatch();
  const { userProfile: { role } = {} } = useConfigCheck();
  const [destroyTimeEntry] = useDestroyTimeEntryMutation();
  const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();
  const [isFormSubmitting, setIsFormSubmiiting] = useState<boolean>(false);

  const initialState = {
    dateRange: [null, null] as DateRange,
    currentPage: 1,
    itemsPerPage: pageSizeOptions[0],
    ordering: Boolean(0) // default ascending
  };
  const pageName = 'timeTracking';
  const savedFilters = useAppSelector(state => state.filters[`${pageName}Filters`]);
  const [filters, setFilters] = useState<FilterValues>(savedFilters || initialState);
  const [finalFilter, setFinalFilter] = useState<FilterValues>(filters);
  const [filterApplied, setFilterApplied] = useState(savedFilters ? hasTimeTrackingListActiveFilters(savedFilters) : false);      //track state whether filter is applied or not
  const { dateRange, currentPage, itemsPerPage, ordering } = finalFilter;

  //generate url based on filter options
  const url = useMemo(() => {
    const params = new URLSearchParams();
    if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
    if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
    if (currentPage) params.append('page', currentPage.toString());
    if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
    params.append('ordering', ordering ? orderingMap[1] : orderingMap[0]);
    return params.toString();
  }, [finalFilter]);

  const { data, isFetching, isError, error, isSuccess } = useDriverTimeEntryQuery(url)

  // Function to update filters
  const updateFilters = (updates: Partial<FilterValues>) => {
    setFilters(prev => ({ ...prev, ...updates }));
    setFinalFilter(prev => ({ ...prev, ...updates }));
  };
  //page change handler
  const handlePageChange = (newPage?: number) => {
    updateFilters({ currentPage: newPage });
  }
  //page size change handler
  const handlePageSizeChange = (size: OptionType) => {
    updateFilters({ itemsPerPage: size });
  }
  // apply filter handler
  const handleApplyFilter = () => {
    const hasAnyFilterChanges = hasSubmittedTimeListActiveFilters(filters);
    setFilterApplied(hasAnyFilterChanges);
    updateFilters({ ...filters, currentPage: 1 });
    return hasAnyFilterChanges;  //return value to close popover in mobile view
  }
  //reset filter handler
  const handleResetFilter = () => {
    setFilters(initialState);
    setFinalFilter(initialState);
    setFilterApplied(false);
    dispatch(clearPageFilters(pageName));
  }

  //persist filters 
  useEffect(() => {
    dispatch(savePageFilters({ page: pageName, filters }));
  }, [filters]);
  //set error if there is network issue
  const [networkError, setNetworkError] = useState<string>("");
  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else if (isSuccess && data) {
      setNetworkError("");
    }
  }, [isError, isSuccess, data]);

  const dailySummary = data?.timeentries?.daily_summary ?? [];
  const hasEntries = dailySummary.length > 0;
  //create time entry handler
  const handleSaveTimeEntry = async (data: any) => {
    setIsFormSubmiiting(true);
    try {
      await partialUpdateTimeEntry({ id: data.id, ...data });
    } catch (error) {
      console.error('Failed to update time entry', error);
    } finally {
      setIsFormSubmiiting(false)
    }
  }
  //remove time entry handler
  const handleRemoveTimeEntry = async (id: number) => {
    setIsFormSubmiiting(true);
    try {
      await destroyTimeEntry(id);
    } catch (error) {
      console.error('Failed to delete time entry', error);
    } finally {
      setIsFormSubmiiting(false);
    }
  };
  return (
    <>
      <TrackingPageHeader />
      <TimeEntryPanel />
      <Divider w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} mx="auto" color={"#D3D3D3"} my={3} />
      {/* <SubmittedTimeEntries /> */}
      <Box w="full" mb={10}>
        {isFormSubmitting && <OverlayLoader />}
        {/* <MainPageHeader breadCrumbItems={breadCrumbItems} /> */}
        <Container mt={4} direction="column">
          {!isError ?
            <PaginatedListLayout
              isLoading={isFetching}
              title={"My Time"}
              filters={filters}
              setFilters={setFilters}
              totalPage={data?.totalpage}
              filterApplied={filterApplied}
              handleApplyFilter={handleApplyFilter}
              handleResetFilter={handleResetFilter}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            >
              {
                !isFetching ?
                  hasEntries && (role == "swamper" || role == "driver" || role == 'driver-lead' || role == "finance") ? (
                    <>
                      <Flex w={"full"} justifyContent={"space-between"} px={2} alignItems={"flex-end"}>
                        <HStack>
                          {
                            filterApplied &&
                            <FilterTile icon="clock" title="Total hours" detail={`${Number(data?.timeentries?.grand_total_hours).toFixed(2)} Hours` || "--"} w={{ base: "140px", sm: "200px" }} />
                          }
                        </HStack>
                        <HStack alignItems={'center'} cursor={"pointer"} onClick={() => setFinalFilter(prev => ({ ...prev, ordering: !(ordering) }))}>
                          <KnownIcon name={ordering ? "ascending" : "descending"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                          <Text noOfLines={1} fontSize={[12, 14, 16]} color={"#455360"}>{ordering ? `Sort Ascending` : `Sort Descending`}</Text>
                        </HStack>
                      </Flex>
                      {data?.timeentries?.daily_summary?.map((item: TimeEntryDetail, index: number) => (
                        <TimeEntryCard key={index} timeEntry={item} onSave={handleSaveTimeEntry} onRemove={handleRemoveTimeEntry} />
                      ))}
                    </>)
                    :
                    <Box flex={1} alignContent={"center"}>No time entries found</Box>
                  :
                  <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                    <PuffLoader
                      color="#3D82CE"
                      size={80}
                    />
                  </Flex>
              }
            </PaginatedListLayout>
            :
            <ErrorBox show={isError} error={error} message={networkError} />
          }
        </Container>
      </ Box>
    </>
  )
}
