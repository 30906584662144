import { useEffect } from 'react';
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import Select, { SingleValue } from 'react-select';
import { useConfigCheck } from '../../../app/hooks';
import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
interface SelectFieldProps extends FieldProps {
  options: { value: number | string | undefined; label: string }[]
  isRequired?: boolean
  setIsValue?(data: number | null): void
  isClearable?: boolean
  disabled?: boolean;
  w?: object
}

export function SelectField({
  label,
  name,
  registerOptions,
  helper,
  options,
  isRequired,
  setIsValue,
  isClearable = false,
  disabled = false,
  ...rest
}: SelectFieldProps) {
  const { userProfile: { id: currentUserId } = {} } = useConfigCheck();
  const pathname = useLocation().pathname;
  const isTrackingPage = pathname.includes("/user/tracking")
  const { register, formState, setValue, watch, clearErrors } = useFormContext();
  const selectedValue = watch(name); // Track current value

  const defaultRegisterOptions = {
    required: isRequired ? "Please pick one" : false,
    valueAsNumber: options?.some((option) => typeof option.value === "number")
  }
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  // Format selected value for react-select
  const currentOption = options.find(
    (option) => option.value === selectedValue
  );

  //handle select change event
  const handleChange = (
    selectedOption: SingleValue<{ value: number | string | undefined; label: string }>
  ) => {
    const value = selectedOption?.value ?? null;
    setIsValue?.(value !== null && typeof value === 'number' ? value : null);
    setValue(name, value);
    clearErrors(name);
  };

  //set default  activity if there is no activity selected for activity dropdown only
  const defaultActivity = isTrackingPage ? options.find((item) => item.label === 'Shop') : options.find((item) => item.label === 'Tank Truck')
  const defaultWorker = options.find((item) => item.value === currentUserId)

  useEffect(() => {
    if (name === 'activity' && !selectedValue) {
      setValue(name, defaultActivity?.value);
    } else if (name === 'worker' && !selectedValue) {
      setValue(name, defaultWorker?.value)
    }
  }, [])
  return (
    <FormControl isInvalid={!!formState.errors?.[name]} isRequired={isRequired} {...rest}>
      <FormLabel fontWeight={500} color={"#455360"}>{label}</FormLabel>
      <Select
        {...register(name, mergedRegisterOptions)}
        value={currentOption}
        defaultValue={name === "activity" ? defaultActivity : name === "worker" ? defaultWorker : null}
        placeholder="Pick one"
        options={options}
        onChange={handleChange}
        isClearable={isClearable}
        isDisabled={disabled}
        styles={{
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#7B8389",
          }),
          container: (base) => ({
            ...base,
            width: "100%",
            borderRadius: "4px",
            border: formState.errors?.[name] ? "1px solid #E53E3E" : 'none'
          }),
          control: (base) => ({
            ...base,
            height: "48px",
            border: "none",
            backgroundColor: disabled ? "#F0F0F0" : "#ECF2F7", // Change background color when disabled
            cursor: disabled ? "not-allowed" : "default", // Change cursor when disabled
            opacity: disabled ? 0.6 : 1, // Gray out the control when disabled
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor:
              state.isSelected
                ? "#EBEEFF"
                : state.isFocused
                  ? "#D3D8FF" // Adjust this for hover effect contrast
                  : provided.backgroundColor,
            "&:active": {
              backgroundColor: "#EBEEFF",
            },
            color: state.isSelected ? "#0E1628" : "#455360",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
            color: disabled ? "#A0A0A0" : "#0E1628", // Change text color when disabled
          }),
        }}
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}