import { PuffLoader } from "react-spinners";
import { components } from "../../app/api/schema";
import { TicketResource } from "./TicketDetailPage";
import { useState, useMemo, useEffect } from "react";
import { ErrorBox } from "../../components/ErrorBox";
import { Container } from "../../components/Container";
import { TicketCard } from "../../components/TicketCard";
import { PageBanner } from "../../components/PageBanner";
import { FilterTile } from "../../components/FilterTile";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { Box, Flex, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { pageSizeOptions } from "../../features/tickets/ticket_choices";
import { FilterValues, OptionType } from "../../features/tickets/tickets";
import { PaginatedListLayout } from "../../layout/areas/PaginatedListLayout";
import { useAppDispatch, useAppSelector, useConfigCheck } from "../../app/hooks";
import { useListingPaginatedTicketDataQuery } from "../../features/tickets/ticketsApi";
import { savePageFilters, clearPageFilters } from "../../features/tickets/filterSlice";
import { formatMinutesAsHours, getDateString, hasTicketListActiveFilters } from "../../app/utils";

type DateRange = [Date | null, Date | null];
export type TicketData = components["schemas"]["Paginated_Ticket"];

const orderingMap: Record<number, string> = {
    0: "created", //ascending order
    1: "-created" //descending order
};

export function TicketsPage() {
    const dispatch = useAppDispatch();
    const { userProfile: { role } = {} } = useConfigCheck();

    const initialState = {
        status: [],
        searchterm: "",
        dateRange: [null, null] as DateRange,
        currentPage: 1,
        itemsPerPage: pageSizeOptions[0],
        ordering: Boolean(0) // default ascending
    };
    const pageName = 'ticketList';
    const savedFilters = useAppSelector(state => state.filters[`${pageName}Filters`]);
    const [filters, setFilters] = useState<FilterValues>(savedFilters || initialState);
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters);
    const [filterApplied, setFilterApplied] = useState(savedFilters ? hasTicketListActiveFilters(savedFilters) : false);
    const { status, searchterm, dateRange, currentPage, itemsPerPage, lsd, ordering } = finalFilter;

    //generate url based on filter options
    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (searchterm && searchterm?.trim().length > 0) params.append('search', `${searchterm}`);
        if (lsd) params.append('lsd', lsd?.label);
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        if (currentPage) params.append('page', currentPage.toString());
        if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
        params.append('ordering', ordering ? orderingMap[1] : orderingMap[0]);
        status && status.forEach(status => {
            params.append('status', status.value.toString());
        });
        return params.toString();
    }, [finalFilter]);

    const { data, isFetching, error, isError, isSuccess } = useListingPaginatedTicketDataQuery(url);

    //update filters
    const updateFilters = (updates: Partial<FilterValues>) => {
        setFilters(prev => ({ ...prev, ...updates }));
        setFinalFilter(prev => ({ ...prev, ...updates }));
    };
    // Search handler
    const handleSearch = (data: string) => {
        updateFilters({ searchterm: data, ...(data && { currentPage: 1 }) });
    };
    //page change handler
    const handlePageChange = (newPage?: number) => {
        updateFilters({ currentPage: newPage });
    };
    //page size change handler
    const handlePageSizeChange = (size: OptionType) => {
        updateFilters({ itemsPerPage: size });
    };
    // apply filter handler
    const handleApplyFilter = () => {
        const hasAnyFilterChanges = hasTicketListActiveFilters(filters);
        setFilterApplied(hasAnyFilterChanges);
        updateFilters({ ...filters, currentPage: 1 });
        return hasAnyFilterChanges; //return value to close popover in mobile view
    }
    //reset filters handler
    const handleResetFilter = () => {
        setFilters(initialState);
        setFinalFilter(initialState);
        setFilterApplied(false);
        dispatch(clearPageFilters(pageName));
    };
    //persist filters 
    useEffect(() => {
        dispatch(savePageFilters({ page: pageName, filters }));
    }, [filters]);
    //set error if there is network issue
    const [networkError, setNetworkError] = useState<string>("");
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);

    //manage state for ticket selection
    const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
    const validTickets = data?.tickets?.filter(
        (item: TicketResource) => item.status === "approved" || item.status === "coded"
    ).map((item) => item.id) || []
    //select all ticket handler
    const HandleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        if (checked) {
            setSelectedTickets(validTickets);
        } else {
            setSelectedTickets?.([]);
        }
    }

    const title = data ? `${data?.total} Ticket Results` : '';
    //bread crumb items
    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'View All Tickets', isCurrentPage: true }
    ];
    return (
        <>
            {
                role === "customer" ?
                    <PageBanner title="Ticket" helper="Summary" /> :
                    <MainPageHeader breadCrumbItems={breadCrumbItems} />
            }
            {
                !isError ?
                    <Box w="full" mb={10}>
                        <Container mt={2} >
                            <PaginatedListLayout
                                isLoading={isFetching}
                                title={title}
                                filters={filters}
                                setFilters={setFilters}
                                totalPage={data?.totalpage}
                                totalEntries={data?.total}
                                filterApplied={filterApplied}
                                handleSearch={handleSearch}
                                handlePageChange={handlePageChange}
                                handleResetFilter={handleResetFilter}
                                handleApplyFilter={handleApplyFilter}
                                handlePageSizeChange={handlePageSizeChange}
                            >
                                {/* select all checkbox  */}
                                {/* {
                                    <Box w={"full"}>
                                        {selectedTickets?.length > 0 &&
                                            <Checkbox
                                                size='lg'
                                                css={{
                                                    '.chakra-checkbox__control': {
                                                        '&:hover': {
                                                            color: '#3455FF',
                                                            background: 'transparent', // Ensure it overrides the default hover background
                                                        },
                                                        '&[data-checked]:hover': {
                                                            backgroundColor: '#3455FF',
                                                            borderColor: '#3455FF',
                                                            color: 'white',
                                                        },
                                                        '&[data-checked]': {
                                                            backgroundColor: '#3455FF',
                                                            borderColor: '#3455FF',
                                                            color: 'white',
                                                        },
                                                    },
                                                }}
                                                isChecked={selectedTickets.length === validTickets.length}
                                                onChange={HandleSelectAll} >Select All</Checkbox>
                                        }
                                    </Box>
                                } */}
                                {/* {
                                    <Box w={"full"} alignContent={"right"}>
                                        {
                                            role === "customer" && filterApplied && (
                                                <ButtonGroup gap={2}>
                                                    <CustomButton title="Code All" variant="outline" />
                                                    <CustomButton title="Code and Stamp All" variant="outline" />
                                                    <CustomButton title="Print All" variant="outline" />
                                                </ButtonGroup>)
                                        }
                                    </Box>
                                } */}
                                <Flex w={"full"} justifyContent={"flex-end"} px={2}>
                                    <HStack alignItems={'center'} cursor={"pointer"} onClick={() => setFinalFilter(prev => ({ ...prev, ordering: !(ordering) }))}>
                                        <KnownIcon name={ordering ? "ascending" : "descending"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text noOfLines={1} fontSize={[12, 14, 16]} color={"#455360"}>{ordering ? `Sort Ascending` : `Sort Descending`}</Text>
                                    </HStack>

                                </Flex>
                                {!isFetching ?
                                    (data && data?.tickets?.length > 0 ? (
                                        <>
                                            {
                                                role === "customer" && filterApplied && (
                                                    <SimpleGrid w="full" gap={2} columns={{ base: 2, xl: 3 }}>
                                                        <FilterTile icon="shippingBox" title="Total actual volume" detail={data?.total_actual_volume} />
                                                        <FilterTile icon="blueDollar" title="Total cost" detail={Number(data?.total_cost)?.toFixed(2)} />
                                                        <FilterTile icon="clock" title="Total hours worked" detail={formatMinutesAsHours(data?.total_time)} />
                                                    </SimpleGrid>)
                                            }

                                            {data?.tickets.map((item: TicketData) => (
                                                <>
                                                    <TicketCard key={item.id} ticket={item} selectedTickets={selectedTickets} select={setSelectedTickets} />
                                                </>
                                            ))}
                                        </>

                                    ) : (
                                        role === 'customer' && (
                                            finalFilter?.status?.length === 0 &&
                                            finalFilter.dateRange[0] === null &&
                                            finalFilter.dateRange[1] === null &&
                                            finalFilter.searchterm === ""
                                        ) ?
                                            <Box flex={1} alignContent={"center"}>You do not have any tickets requiring a stamp</Box>
                                            :
                                            <Box flex={1} alignContent={"center"}>No tickets found</Box>
                                    ))
                                    :
                                    <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                        <PuffLoader
                                            color="#3D82CE"
                                            size={80}
                                        />
                                    </Flex>
                                }
                            </PaginatedListLayout>
                        </Container>
                    </ Box>
                    :
                    <ErrorBox show={isError} error={error} message={networkError} />
            }
        </>
    )
}

