import Select from "react-select";
import { PuffLoader } from 'react-spinners';
import { ListView } from '../views/ListView';
import { useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { useConfigCheck } from '../../app/hooks';
import { FilterTile } from "../../components/FilterTile";
import { SearchInput } from '../../components/SearchBox';
import ResponsivePagination from 'react-responsive-pagination';
import { DetailHeading } from '../../components/DetailHeading';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { CustomButton } from "../../components/forms/CustomButton";
import { pageSizeOptions } from '../../features/tickets/ticket_choices';
import { FilterValues, OptionType } from '../../features/tickets/tickets';
import { Box, Button, ButtonGroup, Flex, HStack, SimpleGrid, useDisclosure, useMediaQuery, VStack } from '@chakra-ui/react';


interface PaginatedListLayoutProps {
    isLoading?: boolean
    title: string
    filters: FilterValues
    filterApplied: boolean
    setFilters?: Dispatch<SetStateAction<FilterValues>>;
    handleApplyFilter?(): void
    handleResetFilter?(): void
    handlePageChange(page: number): void
    handlePageSizeChange(data: OptionType): void
    handleSearch?(data: string): void
    totalPage?: number
    totalEntries?: number
    children: React.ReactNode | React.ReactNode[]
}

export function PaginatedListLayout({
    isLoading = false,
    title,
    filters,
    filterApplied,
    totalPage,
    children,
    totalEntries = 0,
    setFilters = () => { },
    handleApplyFilter,
    handleResetFilter,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
}: PaginatedListLayoutProps) {
    const { isOnline } = useConfigCheck();
    const [isLargerScreen] = useMediaQuery('(min-width: 900px)')
    const pathName = useLocation().pathname;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { userProfile: { role } = {} } = useConfigCheck();

    const isTrackingPage = pathName.includes("/user/tracking");
    const isTicketsPage = pathName.includes("/user/tickets");
    const isApproveTime = (role !== "customer" && role !== "driver") && pathName.includes("/user/approve-time-entries");

    const getSearchPlaceholders = (role: string, isApproveTime: boolean) => {
        if (isApproveTime) return ["Search by driver name", "Search by worker name", "Search by ticket number"];
        if (role === "customer") return ["Search by driver name", "Search by ticket number", "Search by LSD"]
        if (role !== "driver") return ["Search by driver name", "Search by customer name", "Search by ticket number", "Search by LSD"];
        return ["Search by customer name", "Search by ticket number", "Search by LSD",];
    };

    return (
        <>
            <HStack w={"full"} alignItems={"flex-start"}>
                <ListView
                    isOpen={isOpen}  // have to open drawer for smaller devices
                    onClose={onClose}
                    filters={filters}
                    setFilters={setFilters}
                    filterApplied={filterApplied}
                    handleApplyFilter={handleApplyFilter}
                    handleResetFilter={handleResetFilter}
                />
                <VStack w="full" alignItems={"flex-start"}>
                    {/* {
                        role === "customer" && filterApplied && totalEntries > 0 && (
                            <ButtonGroup gap={2}>
                                <CustomButton title="Code All" variant="outline" />
                                <CustomButton title="Code and Stamp All" variant="outline" />
                                <CustomButton title="Print All" variant="outline" />
                            </ButtonGroup>)
                    } */}
                    <HStack
                        w="full"
                        rowGap={4}
                        justifyContent={"space-between"}
                        alignItems={{ base: "flex-start", md: "center" }}
                        flexDirection={isLargerScreen ? "row" : "column-reverse"}
                    >
                        <HStack w={"full"} justifyContent={"space-between"} alignItems="center" >
                            <DetailHeading title={title} fontSize={{ base: "16px", md: "20px" }} />
                            <Button p={2} display={isLargerScreen ? "none" : "flex"} bgColor={"#F8F8FB"} onClick={() => onOpen()} isDisabled={isOnline ? false : true}>
                                <KnownIcon name='filter' cursor={"pointer"} />
                            </Button>
                        </HStack>
                        {
                            handleSearch && !isTrackingPage && (
                                <SearchInput
                                    handleSearch={handleSearch}
                                    searchTerm={filters.searchterm}
                                    placeholders={role && getSearchPlaceholders(role, isApproveTime)}
                                />)
                        }
                    </HStack>
                    <VStack w={"full"} h={"full"} spacing={5} minH={"80vh"}  >
                        {
                            !isLoading ?
                                children
                                :
                                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                    <PuffLoader
                                        color="#3D82CE"
                                        size={80}
                                    />
                                </Flex>
                        }
                    </VStack>
                    {
                        totalPage && totalPage > 1 && filters.currentPage && isOnline &&
                        <HStack w="full" mt={5} mb={5} justifyContent={"space-between"} >
                            <Box w={{ base: "85px", md: "100px" }}>
                                <Select
                                    value={filters.itemsPerPage}
                                    options={pageSizeOptions}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: "#fff",
                                            borderColor: "#dee2e6"
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "16px",
                                            color: "#7B8389",
                                            backgroundColor: state.isSelected ? "#EBEEFF" : "#fff",
                                            ":hover": {
                                                backgroundColor: !state.isSelected ? "#DDEFFF" : undefined
                                            }
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            fontSize: "16px",
                                            color: "#7B8389", // Color for the selected option when dropdown is closed
                                        }),
                                    }}
                                    components={{
                                        DropdownIndicator: null,
                                        IndicatorSeparator: null,
                                    }}
                                    onChange={(value) => {
                                        value && handlePageSizeChange(value)
                                        handlePageChange(1) // Reset page to 1 when changing items per pages
                                    }
                                    }
                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                    isSearchable={false}
                                />
                            </Box>
                            {
                                isOnline &&
                                <Box>
                                    <ResponsivePagination
                                        total={totalPage || 1}
                                        current={filters.currentPage || 1}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel="‹" nextLabel="›"
                                    />
                                </Box>
                            }
                        </HStack>
                    }
                </VStack>
            </ HStack >
        </>
    )
}
