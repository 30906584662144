import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectLocationById } from "./locationsSlice";
import { selectTerminalById } from "./terminalsSlice";
import { AutocompleteField, AutocompleteSuggestion } from "../../components/forms/fields/AutocompleteField";

interface LocationAutocompleteProps extends FieldProps {
  terminalId?: ExistingOrNew
  placeholder?: string
  isDisabled?: boolean
}

export function LocationAutocomplete({
  label,
  name,
  terminalId,
  helper,
  registerOptions,
  placeholder,
  isDisabled
}: LocationAutocompleteProps) {
  const terminal = useAppSelector((state) =>
    selectTerminalById(state, terminalId || 0),
  )
  const locations = terminal?.locations || []
  const suggestions = locations.map((location) => ({
    value: location.id,
    text: location.name || "unknown",
  }))
  const handleMatch = (suggestion: AutocompleteSuggestion) => {
    console.log("Selected", suggestion)
  }

  return (
    <AutocompleteField
      label={label}
      name={name}
      traitGetter={selectLocationById} //add this to get trait behaviour
      icon="location"
      suggestions={suggestions}
      onMatch={handleMatch}
      helper={helper}
      registerOptions={registerOptions}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  )
}
