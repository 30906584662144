import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Draft } from "../draft/draftSlice";
import { forwardRef, useMemo, useState } from "react";
import { Container } from "../../components/Container";
import DriverHoursForm from "../draft/DriverHoursForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { useAppSelector, useCustomToast } from "../../app/hooks";
import { selectTicketTimeEntries } from "../tickets/ticketsSlice";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { useCreateTimeEntryMutation, usePartialUpdateTimeEntryMutation } from "./timeEntriesApi";
import { addLocalEntry, LocalEntry, removeLocalEntriesbyActivity, selectLocalEntries, updateLocalEntry } from "./localEntriesSlice";
interface AdditionalItemsPanelProps extends WrapperProps {
    dataresource?: Draft
}
export const DriverHoursPanel = forwardRef<HTMLDivElement, AdditionalItemsPanelProps>(
    function DriverHoursPanel({ dataresource = {} as Draft, resourceid, isdraft }: AdditionalItemsPanelProps, ref) {
        const dispatch = useDispatch();
        const { showToast } = useCustomToast();
        const [createTimeEntry] = useCreateTimeEntryMutation();
        const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();

        const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

        //check for there is time entries in current draft
        const localEntries = useAppSelector(selectLocalEntries)
        const filteredTimeEntries = useMemo(() => localEntries.filter((item: LocalEntry) => item.ticketDraft === dataresource?.number), [dataresource?.number, localEntries])
        const ticketTimeEntries: any = useAppSelector(selectTicketTimeEntries(Number(resourceid)))

        const handleTimeEntrySave = async (data: any, index?: number | null) => {
            setIsSubmitting(true)
            try {
                if (isdraft) {
                    if (typeof index === "number" && index >= 0) { // Ensure 0 is treated as valid
                        dispatch(updateLocalEntry({
                            index,
                            updatedData: data
                        }));
                    }
                    else {
                        // Add new local entry
                        dispatch(addLocalEntry({ ticketDraft: dataresource?.number, ...data }))
                    }
                }
                else {
                    if (data.id) {
                        // Update existing time entry in the database
                        const { worker, ...rest } = data
                        await partialUpdateTimeEntry({ id: data.id, ...rest })
                    } else {
                        // Create new time entry in the database
                        const { id: removedId, ...cleanData } = data;
                        cleanData.ticket = resourceid;
                        await createTimeEntry(cleanData).unwrap();
                    }
                }
            }
            catch (error) {
                console.error("Action failed:", error);
                showToast({
                    status: 'error',
                    description: "Something went wrong",
                });
            } finally {
                setIsSubmitting(false);
            }
        }

        //remove handler
        const handleTimeEntryRemove = (index: number) => {
            if (isdraft) {
                if (typeof index === "number" && index >= 0) {
                    const filteredEntries = filteredTimeEntries.filter((item, idx) => {
                        return !(idx === index)
                    })
                    dispatch(removeLocalEntriesbyActivity(filteredEntries))
                }
            }
        }

        return (
            <Box w="full" >
                {isSubmitting && <OverlayLoader />}
                <Container >
                    <PanelWrapper title="Hours" >
                        <Box w="full" borderRadius={"8px"} >
                            <DriverHoursForm timeEntries={isdraft ? filteredTimeEntries : ticketTimeEntries} onSave={handleTimeEntrySave} onRemove={handleTimeEntryRemove} isdraft={isdraft} />
                        </Box>
                    </PanelWrapper>
                </Container>
            </Box >
        )
    })
