import {
  format,
  parse,
  setHours,
  parseISO,
  setMinutes,
  differenceInMinutes,
} from "date-fns"
import * as yup from "yup"
import { components } from "../app/api/schema"
import { FilterValues } from "../features/tickets/tickets"
import { pageSizeOptions } from "../features/tickets/ticket_choices"
import betaLargeLogo from "/images/large_logo_beta.png"
import prodLargeLogo from "/images/large_logo_prod.png"
import betaSmallLogo from "/images/small_logo_beta.png"
import prodSmallLogo from "/images/small_logo_prod.png"

type ModeEnum = "beta" | "prod"
type SizeEnum = "small" | "large"

export function getLogo(mode: ModeEnum, size: SizeEnum) {
  return mode === "beta"
    ? size === "large"
      ? betaLargeLogo
      : betaSmallLogo
    : size === "large"
    ? prodLargeLogo
    : prodSmallLogo
}

// export function combineDateTime(date: Date | string, timeString: string) {
//   let time: Date

//   // Parse the time string into a Date object
//   if (timeString.includes("AM") || timeString.includes("PM")) {
//     time = parse(timeString, "h:mm a", new Date())
//   } else {
//     const [hours, minutes] = timeString.split(":")
//     time = setHours(
//       setMinutes(new Date(), parseInt(minutes, 10)),
//       parseInt(hours, 10),
//     )
//   }

//   const combinedDateTime = setHours(
//     setMinutes(new Date(date), time.getMinutes()),
//     time.getHours(),
//   )
//   const formattedDateTime = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ssxxx")

//   return formattedDateTime
// }

export function formatMinutesAsHours(minutes?: number | null) {
  if (minutes) {
    const hours = minutes / 60.0
    const roundedHours = Math.round(hours * 4) / 4
    return roundedHours.toFixed(2)
  } else {
    return 0.0
  }
}

export function formatDateOnly(dateTime?: string | Date | null) {
  if (dateTime instanceof Date) {
    return format(dateTime, "MM/dd/yyyy")
  } else if (dateTime) {
    return format(new Date(dateTime), "MM/dd/yyyy")
  } else {
    return ""
  }
}

// export function formatTimeDateOnly(dateTime?: string | Date | null) {
//   if (dateTime instanceof Date) {
//     return format(dateTime, "MM/dd/yyyy hh:mm a")
//   } else if (dateTime) {
//     return format(new Date(dateTime), "MM/dd/yyyy hh:mm a")
//   } else {
//     return ""
//   }
// }

export function formatTimeOnly(dateTime?: string | Date | null) {
  if (dateTime instanceof Date) {
    return format(dateTime, "hh:mm a")
  } else if (dateTime) {
    return format(new Date(dateTime), "hh:mm a")
  } else {
    return ""
  }
}

export function calculateDeltaMinutes(
  time1: string | Date,
  time2: string | Date,
): number {
  const parseToValidDate = (time: string | Date): Date => new Date(time)

  // Parse the inputs to Date objects and remove milliseconds
  const parsedTime1 = parseToValidDate(time1)
  const parsedTime2 = parseToValidDate(time2)

  parsedTime1.setMilliseconds(0)
  parsedTime2.setMilliseconds(0)

  // Calculate the difference in minutes
  const minuteDifference = differenceInMinutes(parsedTime2, parsedTime1)

  // Round to nearest 15-minute interval if needed
  return Math.round(minuteDifference / 15) * 15
}

export function getRole(string: string) {
  const userRoles = [
    { value: "customer", label: "Customer" },
    { value: "swamper", label: "Swamper" },
    { value: "driver", label: "Driver" },
    { value: "driver-lead", label: "Driver Lead" },
    { value: "app-administrator", label: "App Administrator" },
    { value: "super-admin", label: "Super Admin" },
    { value: "finance", label: "Finance Lead" },
  ]

  return userRoles.find((role) => role.value === string)?.label
}

// export function capFirstLetter(string: string) {
//   return string.charAt(0).toUpperCase() + string.slice(1)
// }

export function getDateString(string: string | Date) {
  let formattedDate
  if (typeof string === "string") {
    const date = parseISO(string)
    formattedDate = format(date, "MMMM d, yyyy")
  } else {
    const date = new Date(string)
    formattedDate = format(date, "yyyy-MM-dd")
  }
  return formattedDate
}

export function formateDate(dateString: string) {
  return format(new Date(dateString), "MMMM dd, yyyy")
}

export function getDateTimeString(string: string) {
  let formattedDate
  const date = parseISO(string)
  formattedDate = format(date, "MMMM d, yyyy  h:mm a")
  return formattedDate
}

export const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`
}
export const baseValidationSchema = yup
  .object({
    new_password: yup
      .string()
      .required("please enter this field")
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      .trim()
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        getCharacterValidationError("special"),
      ),
    confirm_new_password: yup
      .string()
      .required("please enter this field")
      .oneOf([yup.ref("new_password")], "Your passwords do not match."),
  })
  .required()

export const getGroupedTicketTimeEntry = (data: any = []) => {
  return data?.reduce((acc: any, entry: any) => {
    acc[entry.activity_name] =
      (acc[entry.activity_name] || 0) +
      calculateDeltaMinutes(entry.start, entry.finish)
    return acc
  }, {})
}
export const getGroupedDraftTimeEntry = (data: any = []) => {
  return data?.reduce((acc: any, entry: any) => {
    acc[entry.activity] =
      (acc[entry.activity] || 0) +
      calculateDeltaMinutes(entry.start, entry.finish)
    return acc
  }, {})
}

export function stringifyDate(date: any) {
  return date && format(date, "MM/dd/yyyy")
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const calculateAmount = (
  rate: number,
  quantity: number,
  unit: string,
) => {
  if (unit === "%") {
    return Number(((rate * quantity) / 100).toFixed(2))
  }
  return Number((rate * quantity).toFixed(2))
}

export const transformToCanadianFormat = (value: string) => {
  const numericValue = value?.replace(/\D/g, "")
  if (numericValue?.length > 3) {
    // Formatting to Canadian pattern ###-###-####
    const formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(
      3,
      6,
    )}-${numericValue.slice(6, 10)}`
    // Set the formatted value in the field
    return formattedValue
  }
}

export const hasTicketListActiveFilters = (filters: FilterValues): boolean => {
  return (
    (filters?.status?.length ?? 0) > 0 ||
    filters?.dateRange[0] !== null ||
    filters?.dateRange[1] !== null ||
    (filters?.lsd !== null && filters.lsd !== undefined) // Add this if LSD is part of ticket list
    // filters.searchterm !== "" ||
    // filters?.currentPage !== 1
    // filters?.itemsPerPage?.value !== pageSizeOptions[0]?.value ||
    // filters?.itemsPerPage?.label !== pageSizeOptions[0]?.label
  )
}

export const hasApproveTimeListActiveFilters = (
  filters: FilterValues,
): boolean => {
  return (
    filters.entryType !== "all" ||
    filters.locked !== false ||
    filters.customer !== null ||
    filters.user !== null ||
    filters.ordering !== false ||
    (filters?.activity?.length ?? 0) > 0 ||
    filters?.dateRange[0] !== null ||
    filters?.dateRange[1] !== null
    // filters.searchterm !== "" ||
    // filters?.currentPage !== 1 ||
    // filters?.itemsPerPage?.value !== pageSizeOptions[0]?.value ||
    // filters?.itemsPerPage?.label !== pageSizeOptions[0]?.label
  )
}
export const hasTimeTrackingListActiveFilters = (
  filters: FilterValues,
): boolean => {
  return filters?.dateRange[0] !== null || filters?.dateRange[1] !== null
}
export const hasSubmittedTimeListActiveFilters = (
  filters: FilterValues,
): boolean => {
  return filters?.dateRange[0] !== null || filters?.dateRange[1] !== null
}
type OptionType = { value: string | number; label: string }
export const sortSelectOptionsByLabel = (
  options: OptionType[],
): OptionType[] => {
  return [...options].sort((a, b) => {
    // Convert labels to lowercase for case-insensitive sorting
    const labelA = a.label.toLowerCase()
    const labelB = b.label.toLowerCase()

    if (labelA < labelB) return -1
    if (labelA > labelB) return 1
    return 0
  })
}
