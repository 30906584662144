import { Flex } from "@chakra-ui/react";
import { PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { components } from "../../app/api/schema";
import { ErrorBox } from "../../components/ErrorBox";
import { MainPageHeader } from "../../components/MainPageHeader";
import { Params, useLocation, useParams } from "react-router-dom";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { LoadInfoPanel } from "../../features/tickets/LoadInfoPanel";
import { ServicesPanel } from "../../features/tickets/ServicesPanel";
import { LabelInfoPanel } from "../../features/tickets/LabelInfoPanel";
import { selectTicketById } from "../../features/tickets/ticketsSlice";
import { UnloadInfoPanel } from "../../features/tickets/UnloadInfoPanel";
import { TicketDetailWrapper } from "../../components/TicketDetailWrapper";
import { BasicDetailPanel } from "../../features/tickets/BasicDetailPanel";
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { ChargeDetailPanel } from "../../features/tickets/ChargeDetailPanel";
import { TicketDetailPageHeader } from "../../components/TicketDetailPageHeader";
import { LocationsDetailsPanel } from "../../features/tickets/LocationsDetailsPanel";
import { DriverHoursDetailPanel } from "../../features/tickets/DriverHoursDetailPanel";
import { AttachmentsDetailsPanel } from "../../features/tickets/AttachmentsDetailsPanel";

export type TankGaugeDetailsResource = components["schemas"]["Tank_Gauge_Record"];
export type ServiceDetailsResource = components["schemas"]["Service"];
export type ChargeDetailsResource = components["schemas"]["Charge"];
export type TicketResource = components["schemas"]["Ticket"];


export function TicketDetailPage() {
    const { state: locationState } = useLocation();
    const { id: ticketId } = useParams<Params>();
    const { data, isLoading, isError, error, isSuccess, refetch } = useRetrieveTicketQuery(Number(ticketId));
    // Check if there's any local state and refetch the ticket if necessary
    useEffect(() => {
        if (locationState) {
            refetch(); //refetch ticket detail if navigate to this page from approvetime page or tracking page 
        }
    }, [locationState, refetch]);

    const ticket = useAppSelector((state) =>
        ticketId ? selectTicketById(state, ticketId) : null);

    //set error if there is network issue
    const [networkError, setNetworkError] = useState<string>('');
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            // Clear the error if data fetch is successful
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);

    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        ...(
            locationState ?
                [
                    { label: locationState.from.label, path: locationState.from.path },
                    { label: ticket?.number ? `#${ticket?.number}` : '', isCurrentPage: true },
                ]
                :
                !isError ? [
                    { label: 'View All Tickets', path: '/user/tickets' },
                    { label: ticket?.number ? `#${ticket?.number}` : '', isCurrentPage: true },
                ] : []),
    ];

    return (
        <>
            <MainPageHeader isLoading={isLoading} breadCrumbItems={breadCrumbItems} />
            {isLoading || !ticket ? (
                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                    <PuffLoader color="#3D82CE" size={80} />
                </Flex>
            ) : isError ? (
                <ErrorBox show={isError} error={error} message={networkError} />
            ) : (
                <TicketDetailWrapper>
                    <TicketDetailPageHeader />
                    <BasicDetailPanel />
                    <LocationsDetailsPanel />
                    <LabelInfoPanel />
                    <LoadInfoPanel />
                    <UnloadInfoPanel />
                    <DriverHoursDetailPanel />
                    <ServicesPanel />
                    <ChargeDetailPanel />
                    <AttachmentsDetailsPanel />
                </TicketDetailWrapper>
            )}
        </>
    )
}
