import { useEffect, useMemo, useState } from "react";
import { FieldProps } from "../../forms";
import { OptionType } from "../tickets/tickets";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { selectAllCustomers } from "./customersSlice";
import { CustomSelect } from "../../components/forms/fields/CustomeSelectField";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, VStack } from "@chakra-ui/react";

interface CustomerAutocompleteProps extends FieldProps { }

export function CustomerAutocomplete({
  name,
  label,
  helper,
  registerOptions,
}: CustomerAutocompleteProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get all customers
  const customers = useAppSelector(selectAllCustomers);

  useEffect(() => {
    setIsLoading(customers.length === 0); // If customers are empty, set loading to true
  }, [customers]);

  // Memoize options to avoid unnecessary re-computation
  const suggestions = useMemo(() => {
    return customers
      .filter(customer => customer?.profile !== null)
      .map(customer => ({
        value: customer.id,
        label: customer.name,
      }));
  }, [customers]);


  const { formState: { errors }, setValue, register, trigger, watch } = useFormContext()
  const selectedValue = watch(name); // Track current value

  // Format selected value for react-select
  const currentOption = suggestions?.find(
    (item) => item.value === selectedValue
  );

  const handleSelect = (selectedOption: OptionType | null) => {
    setValue(name, selectedOption ? selectedOption.value : null);
    trigger(name)
    //set consignor_contact null if customer field is changing
    setValue("consignor_contact", null);
  };

  return (
    <FormControl isInvalid={errors[name] !== undefined} isRequired>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel color="#455360">{label}</FormLabel>
        <CustomSelect
          icon="blueUser"
          isLoading={isLoading}
          name={name}
          value={currentOption}
          options={suggestions}
          placeholder="Select customer"
          handleChange={handleSelect}
          register={register}
          registerOptions={registerOptions}
          isClearable={true}
          isSearchable={true}
          errors={errors}
        />
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl >
  )
}
