import { Form } from "./Form";
import { CustomButton } from "./CustomButton";
import { useConfigCheck } from "../../app/hooks";
import { ButtonGroup, Modal, ModalProps, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Divider, Box } from "@chakra-ui/react";
import { Mode } from "../../pages/stamp/StampTicketDetail";
interface ModalFormProps extends ModalProps {
  title: string
  defaultValues?: any
  onCancel(): void
  onSave?(data: any): void
  isdraft?: boolean
  mode?: Mode
}

export function ModalForm({
  title,
  onSave,
  onClose,
  onCancel,
  children,
  defaultValues,
  isdraft,
  mode = Mode.Default,
  ...modalProps
}: ModalFormProps) {
  const { isOnline } = useConfigCheck()

  //modal close handler
  const handleCancel = () => {
    onClose()
    onCancel()
  }

  const handleSave = (data: any) => {
    console.log("Saved modal", data)
    // If in code_only mode, remove signature-related fields
    if (mode === 'code_only') {
      const { digital_sign, ...codeOnlyData } = data;
      onSave && onSave(codeOnlyData);
    } else {
      onSave && onSave(data);
    }
    onClose()
  }

  const saveButtonTitle = mode === 'code_only'
    ? 'Save Codes and Notify'
    : 'Save';

  return (
    <Modal blockScrollOnMount={true} closeOnOverlayClick={false} onClose={handleCancel} {...modalProps}>
      <ModalOverlay />
      <ModalContent >
        <Form defaultValues={defaultValues} onSave={handleSave}>
          <ModalHeader py={4} fontSize={"20px"} color={"#05004E"}>{title}</ModalHeader>
          <Box px={6} mb={2}>
            <Divider />
          </Box>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" gap={4}>
              {children}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"center"} mb={2}>
            <ButtonGroup gap={"20px"}>
              <CustomButton w={{ base: '120px', md: '160px' }} title={saveButtonTitle} type="submit" isDisabled={(isOnline || isdraft) ? false : true} />
              <CustomButton w={{ base: '120px', md: '160px' }} title="Cancel" colorScheme="grey" onClick={handleCancel} />
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
