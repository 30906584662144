import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { KnownIconName } from "../../components/widgets/KnownIcon";
import { selectAllTerminals, selectTerminalById } from "./terminalsSlice";
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField";
import { useFormContext } from "react-hook-form";

interface TerminalAutocompleteProps extends FieldProps {
  icon?: KnownIconName
  placeholder?: string
}

export function TerminalAutocomplete({
  label,
  name,
  registerOptions,
  helper,
  placeholder
}: TerminalAutocompleteProps) {
  const { formState: { errors }, setValue, trigger, watch, register, reset } = useFormContext()
  const terminals = useAppSelector(selectAllTerminals)
  const suggestions = terminals.map((terminal) => ({
    value: terminal.id,
    text: terminal.name,
  }))

  const [nameField, fieldType] = name.split(".");
  const resetDependentField = () => {
    setValue(`${nameField}.lsd`, ""); // Reset the lsd field
    setValue(`${nameField}.location`, ""); // Reset the location field
  }
  return (
    <AutocompleteField
      name={name}
      label={label}
      icon="blueUser"
      helper={helper}
      suggestions={suggestions}
      placeholder={placeholder}
      registerOptions={registerOptions}
      traitGetter={selectTerminalById} //add this to get trait behaviour
      resetDependentField={resetDependentField}
    />
  )
}
