import { useMemo } from 'react';
import { FieldProps } from '../../../forms';
import { KnownIcon } from '../../widgets/KnownIcon';
import Select, { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Box, Button, HStack } from '@chakra-ui/react';
import { OptionType } from '../../../features/tickets/tickets';
interface CustomSelectProps extends FieldProps {
    register: any;
    options?: OptionType[];
    errors?: Record<string, any>;
    value?: OptionType | OptionType[] | null;
    handleChange: (selectedOption: any) => void;
    onOpen?: () => void;
    isNew?: boolean;
    isMulti?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean
}

const getCustomStyles = (
    name: string,
    icon?: string,
    errors?: Record<string, any>,
    isDisabled?: boolean
): StylesConfig<OptionType, boolean> => (
    {
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#7B8389",
        }),
        container: (base) => ({
            ...base,
            width: "100%",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            border: errors?.[name] ? "2px solid #E53E3E" : "none",
        }),
        control: (base, state) => ({
            ...base,
            height: "48px",
            border: "none",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderRadius: icon ? "none" : "4px",
            borderColor: state.isFocused ? "#3D82CE" : "none",
            boxShadow: state.isFocused ? "0 0 0 2px #3182ce" : "none",
            backgroundColor: state.isFocused ? "transparent" : "#EDF2F7",
            opacity: isDisabled ? 0.4 : 1,
            cursor: isDisabled ? "not-allowed" : "default",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor:
                state.isSelected
                    ? "#EBEEFF"
                    : state.isFocused
                        ? "#EBEEFF"
                        : provided.backgroundColor,
            "&:active": {
                backgroundColor: "#EBEEFF",
            },
            color: "#455360",
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
            color: "#0E1628",
        }),
    });

export const CustomSelect = ({
    name,
    icon,
    value,
    register,
    options,
    errors = {},
    registerOptions = {},
    handleChange,
    placeholder = 'Select an option',
    isLoading,
    isNew = false,
    isMulti = false,
    isClearable = false,
    isSearchable = false,
    isCreatable = false,
    isDisabled = false,
    onOpen
}: CustomSelectProps) => {
    const customStyles = useMemo(() => getCustomStyles(name, icon, errors, isDisabled), [errors, name, isDisabled]);

    return (
        <HStack spacing={0} align="center" w={"full"}>
            {icon && (
                <Box
                    px={4}
                    h="48px"
                    bg="gray.100"
                    display="flex"
                    alignItems="center"
                    borderLeftRadius="4px"
                    justifyContent="center"
                    opacity={isDisabled ? 0.4 : 1}
                    cursor={isDisabled ? "not-allowed" : "auto"}
                >
                    <KnownIcon name={icon} />
                </Box>
            )}
            <Box flex="1">
                {
                    !isCreatable ?
                        <Select
                            isLoading={isLoading}
                            styles={customStyles}
                            {...register(name, registerOptions)}
                            placeholder={placeholder}
                            value={value}
                            options={options}
                            onChange={handleChange}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti={isMulti}
                            isDisabled={isDisabled}
                        />
                        :
                        <CreatableSelect
                            styles={customStyles}
                            {...register(name, registerOptions)}
                            placeholder={placeholder}
                            value={value}
                            options={options}
                            onChange={handleChange}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isDisabled={isDisabled}
                        />
                }
            </Box>
            {
                isNew &&
                <>
                    <Box
                        mr={2}
                        gap={3}
                        position="absolute"
                        right="70px"
                        display="flex"
                        alignItems="center"
                    >
                        <Button h='1.75rem' size='xs' color={"#3455FF"} bgColor={"white"} cursor='none'>
                            New
                        </Button>
                        <KnownIcon name='edit' onClick={onOpen} cursor={"pointer"} />
                    </Box>
                </>
            }
        </HStack>
    );
};

