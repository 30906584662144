import { VStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { TerminalAutocomplete } from "./TerminalAutocomplete";
import { LocationAutocomplete } from "./LocationAutocomplete";
import { InputStreak } from "../../components/forms/InputStreak";
import { LsdInputStreak } from "../../components/forms/LsdInputStreak";
import { LsdPrefixField } from "../../components/forms/fields/LsdPrefixField";
import { LsdAutocomplete } from "../../components/forms/fields/LsdAutocomplete";

interface ConsignorFormProps {
  title?: string,
}

export function ConsignorForm({ title }: ConsignorFormProps) {
  const { watch } = useFormContext()
  const terminalId = watch("consignor.terminal");

  return (
    <>
      <VStack w="full" gap={4} p={[0, 2, 4]}>
        <InputStreak label={title}>
          <TerminalAutocomplete
            label="Company Name"
            name={"consignor.terminal"}
            icon="blueUser"
            placeholder="Consignor sending the shipment"
          />
        </InputStreak>
        <LsdInputStreak label="LSD/Well/Battery">
          <LsdPrefixField label="LSD Prefix" name={"consignor.lsdprefix"} />
          <LsdAutocomplete
            label="Well or Battery LSD location"
            name={"consignor.lsd"}
            terminalId={terminalId}
            placeholder="##-##-##-##-W#"
            isDisabled={!terminalId}
            registerOptions={{ required: terminalId ? "This field is required" : false }}
          />
        </LsdInputStreak>
        <LocationAutocomplete
          label="Point of Origin"
          name={"consignor.location"}
          terminalId={terminalId}
          placeholder="Pick up location"
          isDisabled={!terminalId}
          registerOptions={{ required: terminalId ? "This field is required" : false }}
        />
      </VStack>
    </>
  )
}
