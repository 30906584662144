import { TimeEntryForm } from './TimeEntryForm';
import { useConfigCheck } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalForm } from '../../components/forms/ModalForm';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import { CustomButton } from '../../components/forms/CustomButton';
import { StatusBadges } from '../../components/widgets/StatusBadges';
import { formateDate, formatTimeOnly, getDateString } from '../../app/utils';
import { ApproveTimeEntry, TimeEntriesResource } from '../../pages/user/ApproveSubmittedTimeEntries';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, ButtonGroup, Card, CardBody, Flex, HStack, IconButton, Spacer, Stack, StackDivider, Switch, Text, useDisclosure, VStack } from '@chakra-ui/react';
type TimeEntry = {
    id?: number
    activity: number | null
    start: string
    finish: string
    description?: string
}

interface TimeEntryCardProps {
    timeEntry: ApproveTimeEntry
    onSave(data: any): void
    onRemove(data: any): void
    onApprove?(data: any): void
}

export function TimeEntryCard({ timeEntry, onSave, onRemove, onApprove }: TimeEntryCardProps) {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;
    const isTimeTrackingPage = pathname.includes("/tracking");
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isModOpen, onClose: onModelClose, onOpen: onModelOpen } = useDisclosure();
    const { userProfile: { role } = {}, isOnline } = useConfigCheck();
    const cancelRef = useRef(null);

    //Handle edit time entry model , set default values
    const [defaultValues, setDefaultValues] = useState<TimeEntry>();
    const handleEditTimeEntry = (entry: TimeEntriesResource) => {
        const { id, start, finish, activity, description } = entry
        setDefaultValues({
            id: id,
            activity: activity,
            start: start,
            finish: finish,
            ...(isTimeTrackingPage ? { description } : {})
        })
        onOpen();
    }

    //Time entry save handler
    const handleSaveTimeEntry = async (data: any) => {
        onSave?.({ id: data.id, ...data })
        onClose(); // close the model
    }

    const isApproveTimePage = pathname.includes("user/approve-time-entries")
    const fromState = isApproveTimePage
        ? { label: "Approve Time", path: "/user/approve-time-entries" }
        : { label: "Time Tracking", path: "/user/tracking" };

    //Below code is for approve time entry page
    // const isApproveAllAllowed = timeEntry?.entries?.some((item) => item.is_approved === false)
    const handleApprove = async (e: ChangeEvent<HTMLInputElement>, idOrIds: number | number[], worker?: number, date?: string) => {
        const { checked } = e.target;
        onApprove?.({ id: Array.isArray(idOrIds) ? undefined : idOrIds, ids: Array.isArray(idOrIds) ? idOrIds : undefined, is_approved: checked, worker: worker, date: date });
    };

    // Usage for a single approval
    const handleApproveTime = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        handleApprove(e, id);
    };

    // Usage for approving all
    const handleApproveAllTime = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const ids = timeEntry.entries.map((item) => item.id);
        const worker = timeEntry?.worker_id
        handleApprove(e, ids, worker, timeEntry?.date);
    };

    const [selectedEntry, setSelectedEntry] = useState<number | null>(null);

    const handleDeleteClick = (id: number) => {
        setSelectedEntry(id);
        onModelOpen();
    };
    const handleConfirmModel = () => {
        if (selectedEntry) {
            onRemove(selectedEntry);
        }
        onModelClose();
    }

    return (
        <Fragment >
            <Card w="full">
                <CardBody p={{ base: 2, lg: 5 }}>
                    <Stack divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                        <HStack alignItems={"center"} justifyContent={"space-between"} px={2} py={1} >
                            <VStack w={"60%"} gap={3} alignItems={"stretch"}>
                                <Text fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={600}>{`${getDateString(timeEntry?.date)}`}</Text>
                                {isApproveTimePage &&
                                    <HStack alignItems={'center'}>
                                        <KnownIcon name="worker" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Box w={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }} wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                            <Text noOfLines={1} fontSize={[12, 14, 16]}>{timeEntry?.worker_name}</Text>
                                        </Box>
                                    </HStack>
                                }
                            </VStack>
                            <VStack gap={3}>
                                <Flex minW={{ base: "120px", lg: "150px" }} p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                                    <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text noOfLines={1} isTruncated>{`${Number(timeEntry.total_hours).toFixed(2)} Hours`}</Text>
                                </Flex>
                                {
                                    isApproveTimePage &&
                                    // isApproveAllAllowed &&
                                    <Flex fontSize={{ base: "12px", sm: "16px" }} color={"#455360"} gap={2} alignItems="center">
                                        <Switch
                                            isChecked={timeEntry?.locked}
                                            variant={"boxy"}
                                            isDisabled={!isOnline}
                                            size={"lg"}
                                            onChange={(e) => handleApproveAllTime(e)} display="inline"
                                        />
                                    </Flex>
                                }
                            </VStack>
                        </HStack>
                        <Stack spacing='6'>
                            {
                                timeEntry.entries.map((entry: TimeEntriesResource) => (
                                    <VStack p={4} shadow='md' borderWidth='1px' borderRadius={"4px"} alignItems='stretch' gap={3}>
                                        {
                                            entry?.ticket &&
                                            <HStack alignItems={"center"} justifyContent={"space-between"}>
                                                <Text fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={600}>#{entry?.ticket}</Text>
                                                <Flex gap={2} color="#3455FF" fontSize="12px" cursor={!isOnline ? "not-allowed" : "pointer"} pointerEvents={(!isOnline) ? "none" : undefined}>
                                                    <Text onClick={() => navigate(`/user/tickets/${entry?.ticket_id}`, { state: { from: fromState } })} fontSize={[12, 14, 16]}>View Details</Text>
                                                    <KnownIcon name="link" />
                                                </Flex>
                                            </HStack>
                                        }
                                        <HStack justifyContent={'space-between'} gap={0} alignItems={'center'}>
                                            <HStack flexBasis={["60%", "70%"]} alignItems="center" >
                                                <KnownIcon name="customer" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                                    <Text fontWeight={500} noOfLines={2} fontSize={[12, 14, 16]}>{entry.customer || "No Customer"}</Text>
                                                </Box>
                                            </HStack>
                                            <Flex minW={{ base: "120px", lg: "150px" }} p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                                                <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Text noOfLines={1} isTruncated>{`${Number(entry.hours).toFixed(2)} Hours`}</Text>
                                            </Flex>
                                        </HStack>
                                        <HStack justifyContent={'space-between'} gap={0} alignItems={'center'}>
                                            <HStack alignItems={'center'}>
                                                <KnownIcon name="activity" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                                    <Text noOfLines={2} fontSize={[12, 14, 16]}>{entry.activity_name}</Text>
                                                </Box>
                                            </HStack>
                                            <StatusBadges status={entry.is_approved ? "approved" : "submitted"} />
                                        </HStack>
                                        <HStack gap={[3, 5]} justifyContent={"space-between"}>
                                            <Stack w={"60%"} flexDirection={{ base: isTimeTrackingPage ? "row" : "column", sm: "row" }}>
                                                <Box w="fit-content" minW={"100px"}>
                                                    <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>Start Time</Text>
                                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                                        <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                        <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }} >{formatTimeOnly(entry?.start) || "--"} </Text>
                                                    </Flex>
                                                </Box>
                                                <Spacer borderBottom={"2px dashed #9F9F9F"} display={{ base: "none", sm: "block" }} />
                                                <Box w="fit-content" minW={"100px"}>
                                                    <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>End Time</Text>
                                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                                        <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                        <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }}  >{formatTimeOnly(entry?.finish) || "--"} </Text>
                                                    </Flex>
                                                </Box>
                                            </Stack>
                                            <Stack w={isTimeTrackingPage ? "20%" : "40%"} flexDirection={{ base: "column", sm: "row" }} columnGap={[3, 5]} rowGap={5} justifyContent={isTimeTrackingPage ? "center" : "flex-end"} alignItems={"center"}>
                                                {
                                                    // isApproveTimePage && !entry.ticket &&
                                                    !timeEntry?.locked &&
                                                    isApproveTimePage &&
                                                    <Flex flex={1} fontSize={{ base: "12px", sm: "16px" }} color={"#455360"} gap={2} justifyContent={"center"} alignItems="center">
                                                        Approve:
                                                        <Switch
                                                            sx={{
                                                                '.chakra-switch__track': {
                                                                    bg: 'gray.300', // background when off
                                                                },
                                                                '.chakra-switch__track[data-checked]': {
                                                                    bg: '#3455FF', // custom background color when on
                                                                },
                                                            }}
                                                            isChecked={entry.is_approved}
                                                            onChange={(e) => handleApproveTime(e, entry.id)} display="inline"
                                                            isDisabled={!isOnline}
                                                            size={{ base: "sm", md: "md" }}
                                                        />
                                                    </Flex>
                                                }
                                                <Flex gap={2} >
                                                    {!entry.is_approved && (
                                                        (isTimeTrackingPage
                                                            ? (role === "driver" || role === "swamper") && !entry.ticket
                                                            : true) && (
                                                            <IconButton
                                                                aria-label="edit"
                                                                icon={
                                                                    <KnownIcon
                                                                        name="edit"
                                                                        boxSize={{ md: "20px", lg: "24px" }}
                                                                        cursor="pointer"
                                                                    />
                                                                }
                                                                onClick={() => handleEditTimeEntry(entry)}
                                                                backgroundColor="transparent"
                                                                _hover={{ backgroundColor: "transparent" }}
                                                            />
                                                        )
                                                    )}

                                                    {!entry.is_approved && (
                                                        (isTimeTrackingPage
                                                            ? (role === "driver" || role === "swamper") && !entry.ticket
                                                            : true) && (
                                                            < IconButton
                                                                aria-label="delete"
                                                                icon={<KnownIcon name="delete" boxSize={{ md: "20px", lg: "24px" }} cursor="pointer" />}
                                                                onClick={() => handleDeleteClick(entry.id)}
                                                                backgroundColor="transparent"
                                                                _hover={{ backgroundColor: "transparent" }}
                                                            />
                                                        )
                                                    )}

                                                </Flex>
                                            </Stack>
                                        </HStack>
                                    </VStack>
                                ))
                            }
                        </Stack>

                    </Stack>
                </CardBody>
            </Card >
            <ModalForm
                isdraft={false}
                title={`Edit Time Entry`}
                isOpen={isOpen}
                size={["full", "lg"]}
                defaultValues={defaultValues}
                onClose={onClose}
                onCancel={onClose}
                onSave={handleSaveTimeEntry}
            >
                <TimeEntryForm isTicketPage={false} />
            </ModalForm>
            <AlertDialog
                isOpen={isModOpen}
                leastDestructiveRef={cancelRef}
                onClose={onModelClose}
                size={["xs", "sm"]}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove Entry
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <ButtonGroup>
                                <CustomButton title="Cancel" onClick={onModelClose} />
                                <CustomButton title="Confirm" variant="outline" colorScheme="red" onClick={handleConfirmModel} />
                            </ButtonGroup>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Fragment >
    )
}
