import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const boxy = definePartsStyle((props) => {
    return {
        thumb: {
            position: "relative",
            bg: "white",
            _after: {
                content: '""',
                position: "absolute",
                width: "75%",
                height: "75%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: 'url(/icons/unlock1.svg)', // Lock icon inside the thumb
            },
            _checked: {
                _after: {
                    backgroundImage: 'url(/icons/lock1.svg)',
                }
            }
        },
        track: {
            _checked: {
                bg: '#3455FF', // custom background color when on
            }
        },
    }
});

export const switchTheme = defineMultiStyleConfig({
    variants: { boxy }
});
