import { PuffLoader } from 'react-spinners';
import { components } from '../../app/api/schema';
import { ErrorBox } from '../../components/ErrorBox';
import { useEffect, useMemo, useState } from 'react';
import { Container } from '../../components/Container';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { OverlayLoader } from '../../components/OverlayLoader';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { MainPageHeader } from '../../components/MainPageHeader';
import { BreadcrumbItemType } from '../../components/BreadCrumbs';
import { pageSizeOptions } from '../../features/tickets/ticket_choices';
import { FilterValues, OptionType } from '../../features/tickets/tickets';
import { TimeEntryCard } from '../../features/timeEntries/TimeEntryCard';
import { PaginatedListLayout } from '../../layout/areas/PaginatedListLayout';
import { useListCustomersQuery } from '../../features/customers/customersApi';
import { getDateString, hasApproveTimeListActiveFilters } from '../../app/utils';
import { useListActivitiesQuery } from '../../features/activities/activitiesApi';
import { clearPageFilters, savePageFilters } from '../../features/tickets/filterSlice';
import { useAppDispatch, useAppSelector, useConfigCheck, useCustomToast } from '../../app/hooks';
import { useDayApprovalMutation, useDestroyTimeEntryMutation, usePaginatedTimeEntriesQuery, usePartialUpdateTimeEntryMutation } from '../../features/timeEntries/timeEntriesApi';

export type TimeEntriesResource = components["schemas"]["TimeEntry"]

export interface ApproveTimeEntry {
    date: string,
    worker_name?: string,
    worker_id?: number,
    total_hours: number,
    locked?: boolean
    entries: TimeEntriesResource[]
}

type TicketTypeInterface = {
    ticketTime: string
    nonTicketTime: string
    all: string
}

const ticketTypeMap: TicketTypeInterface = {
    ticketTime: '0',
    nonTicketTime: '1',
    all: '2'
};

const orderingMap: Record<number, string> = {
    0: "date", //ascending order
    1: "-date" //descending order
};

export function ApproveSubmittedTimeEntries() {
    useListCustomersQuery(); // fetch customers on page load
    useListActivitiesQuery(); // fetch activities on page load
    const dispatch = useAppDispatch();
    const { showToast } = useCustomToast();
    const { userProfile: { role } = {} } = useConfigCheck();
    const [dayApproval] = useDayApprovalMutation();
    const [destroyTimeEntry] = useDestroyTimeEntryMutation();
    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();

    //inital state to begin with filters
    const initialState: FilterValues = {
        activity: [],
        customer: null,
        user: null,
        searchterm: '',
        dateRange: [null, null],
        locked: false,
        ticket: null,
        entryType: 'all',
        ordering: Boolean(0), // default ascending
        currentPage: 1,
        itemsPerPage: pageSizeOptions[0],
    }
    const pageName = 'approveTime';
    const savedFilters = useAppSelector(state => state.filters[`${pageName}Filters`]);

    const [isFormSubmitting, setIsFormSubmiiting] = useState<boolean>(false)
    const [filters, setFilters] = useState<FilterValues>(savedFilters || initialState);
    const [filterApplied, setFilterApplied] = useState(savedFilters ? hasApproveTimeListActiveFilters(savedFilters) : false);
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters);
    const { entryType, activity, customer, searchterm, dateRange, currentPage, itemsPerPage, ticket, locked, user, ordering } = finalFilter;

    //generate url based on filter options
    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (searchterm && searchterm?.trim().length > 0) params.append('search', `${searchterm}`);
        if (ticket) params.append('ticket', `${ticket}`);
        if (customer) params.append('customer', customer?.label);
        if (user) params.append('user', user?.value + '');
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        if (currentPage) params.append('page', currentPage.toString());
        if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
        if (entryType) params.append('ticket_type', ticketTypeMap[entryType]);
        params.append('ordering', ordering ? orderingMap[1] : orderingMap[0]);
        params.append('locked', `${locked}`);
        activity && activity.forEach(activity => {
            params.append('activity', activity.value.toString());
        });

        return params.toString();
    }, [finalFilter])

    const { data, isFetching, isError, error } = usePaginatedTimeEntriesQuery(url, { refetchOnMountOrArgChange: true });

    //update filters
    const updateFilters = (updates: Partial<FilterValues>) => {
        setFilters(prev => ({ ...prev, ...updates }));
        setFinalFilter(prev => ({ ...prev, ...updates }));
    };
    // Search handler
    const handleSearch = (data: string) => {
        updateFilters({ searchterm: data, ...(data && { currentPage: 1 }) });
    };
    //page change handler
    const handlePageChange = (newPage?: number) => {
        updateFilters({ currentPage: newPage });
    }
    //page size change handler
    const handlePageSizeChange = (size: OptionType) => {
        updateFilters({ itemsPerPage: size });
    }
    //apply filter handler
    const handleApplyFilter = () => {
        const hasAnyFilterChanges = hasApproveTimeListActiveFilters(filters);
        setFilterApplied(hasAnyFilterChanges);
        setFinalFilter({ ...filters, currentPage: 1 });
        return hasAnyFilterChanges; //return value to close popover in mobile view
    }
    //reset filters handler
    const handleResetFilter = () => {
        setFilters(initialState);
        setFinalFilter(initialState);
        setFilterApplied(false);
        dispatch(clearPageFilters(pageName));
    }

    //persist filters 
    useEffect(() => {
        dispatch(savePageFilters({ page: pageName, filters }));
    }, [filters]);
    //set error if there is network issue
    const [networkError, setNetworkError] = useState("")
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            setNetworkError("")
        }
    }, [isError, isFetching])

    //create time entry handler
    const handleSaveTimeEntry = async (data: any) => {
        try {
            await partialUpdateTimeEntry({ id: data.id, ...data });
            showToast({ status: 'success', description: 'Successfully Updated time entry' });
        } catch (error) {
            console.error('Failed to update time entry', error);
            showToast({ status: 'error', description: 'Something went wrong' });
        }
    }
    //approve time entry handler
    const handleApproveTime = async ({ id, ids, is_approved, worker, date }: { id?: number; ids?: number[]; is_approved: boolean, worker?: number, date?: string }) => {
        setIsFormSubmiiting(true);
        try {
            if (id) {
                const res = await partialUpdateTimeEntry({ id, is_approved });
                if ('error' in res && res.error) throw 'Entry cannot be updated because the associated ticket is already approved';
            } else if (ids?.length && worker) {
                const res = await dayApproval({ ids, worker, locked: is_approved, date: date });
                if ('error' in res && res.error) throw 'Ensure all time entries within the day are approved';
                showToast({ status: 'success', description: `${'data' in res && res?.data?.detail}` });
            }
        } catch (error) {
            showToast({ status: 'error', description: `${error}` });
        } finally {
            setIsFormSubmiiting(false);
        }
    };
    //remove time entry handler
    const handleRemoveTimeEntry = async (id: number) => {
        setIsFormSubmiiting(true);
        try {
            await destroyTimeEntry(id);
        } catch (error) {
            console.error('Failed to delete time entry', error);
        } finally {
            setIsFormSubmiiting(false);
        }
    };
    //bread crumb items
    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'Approve Time', isCurrentPage: true }
    ];

    return (
        <Box w="full" mb={10}>
            {isFormSubmitting && <OverlayLoader />}
            <MainPageHeader breadCrumbItems={breadCrumbItems} />
            <Container mt={4} direction="column">
                {!isError ?
                    <PaginatedListLayout
                        isLoading={isFetching}
                        title={"Approve Time"}
                        filters={filters}
                        setFilters={setFilters}
                        totalPage={data?.totalpage}
                        filterApplied={filterApplied}
                        handleSearch={handleSearch}
                        handlePageChange={handlePageChange}
                        handleResetFilter={handleResetFilter}
                        handleApplyFilter={handleApplyFilter}
                        handlePageSizeChange={handlePageSizeChange}
                    >
                        <Flex w={"full"} justifyContent={"flex-end"} px={2}>
                            <HStack alignItems={'center'} cursor={"pointer"} onClick={() => setFinalFilter(prev => ({ ...prev, ordering: !(ordering) }))}>
                                <KnownIcon name={ordering ? "ascending" : "descending"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                <Text noOfLines={1} fontSize={[12, 14, 16]} color={"#455360"}>{ordering ? `Sort Ascending` : `Sort Descending`}</Text>
                            </HStack>

                        </Flex>
                        {
                            !isFetching ?
                                (data && data?.timeentries?.length > 0 ? (
                                    data?.timeentries.map((item: ApproveTimeEntry, index: number) => (
                                        <TimeEntryCard key={index} timeEntry={item} onSave={handleSaveTimeEntry} onRemove={handleRemoveTimeEntry} onApprove={handleApproveTime} />
                                    ))
                                ) : (
                                    role === 'customer' && (
                                        finalFilter?.status?.length === 0 &&
                                        finalFilter.dateRange[0] === null &&
                                        finalFilter.dateRange[1] === null &&
                                        finalFilter.searchterm === ""
                                    ) ?
                                        <Box flex={1} alignContent={"center"}>You do not have any tickets requiring a stamp</Box>
                                        :
                                        <Box flex={1} alignContent={"center"}>No time entries found</Box>
                                ))
                                :
                                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                    <PuffLoader
                                        color="#3D82CE"
                                        size={80}
                                    />
                                </Flex>
                        }
                    </PaginatedListLayout>
                    :
                    <ErrorBox show={isError} error={error} message={networkError} />
                }
            </Container>
        </ Box>
    )
}
