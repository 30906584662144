import { ChangeEvent } from "react";
import { formatMinutesAsHours } from "../../app/utils";
import { DetailTile } from "../../components/DetailTile";
import { selectTicketTimeEntries } from "./ticketsSlice";
import { PanelWrapper } from "../../components/PanelWrapper";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { usePartialUpdateTimeEntryMutation } from "../timeEntries/timeEntriesApi";
import { Box, Flex, HStack, StackDivider, Switch, Text, VStack } from "@chakra-ui/react";
interface DriverHourPanelProps extends WrapperProps {
    dataresource?: TicketResource;
}

export function DriverHoursDetailPanel({
    resourceid,
    dataresource: ticket,
}: DriverHourPanelProps) {
    const { userProfile: { role } = {}, isOnline } = useConfigCheck();
    const ticketTimeEntries = useAppSelector(selectTicketTimeEntries(Number(resourceid)));
    const grandTotal = ticketTimeEntries?.reduce((acc, item) => acc += (item?.hours || 0), 0);

    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation();

    const handleApproveTime = async (e: ChangeEvent<HTMLInputElement>, timeEntryId: number) => {
        const { checked } = e.target;
        await partialUpdateTimeEntry({ id: timeEntryId, is_approved: checked });
    };

    const renderDriverHoursDetail = () => {
        // return Object.entries(driverHours).map(([activity, { totalHours, entries }]) => {
        return ticketTimeEntries?.map((item) => {
            // For each activity, we'll display switches for all related entries
            return (
                <VStack key={item.id} align="stretch" spacing={2}>
                    <HStack
                        alignItems="flex-start"
                        gap={{ base: 2, lg: "60px" }}
                        divider={<StackDivider />}
                    >
                        <DetailTile flexBasis={{ base: "50%", md: "30%" }} detail={item.activity_name} />
                        <DetailTile
                            flexBasis={{ base: "50%", md: "30%" }}
                            px={{ base: 2, sm: 5 }}
                            detail={Number(item.hours).toFixed(2)}
                        />
                        {
                            role === "driver-lead" &&
                            (ticket?.status === "submitted" || ticket?.status === "unapproved") && (
                                <VStack
                                    flexBasis={{ base: "50%", md: "30%" }}
                                    align="stretch"
                                    spacing={2}
                                >
                                    {/* {!item.approval && */}
                                    <Flex
                                        key={item.id}
                                        fontSize={{ base: "12px", sm: "16px" }}
                                        color="#455360"
                                        gap={[3, 5]}
                                        alignItems="center"
                                    >
                                        <Switch
                                            sx={{
                                                ".chakra-switch__track": {
                                                    bg: "gray.300",
                                                },
                                                ".chakra-switch__track[data-checked]": {
                                                    bg: "#3455FF",
                                                },
                                            }}
                                            isChecked={item.is_approved}
                                            onChange={(e) => handleApproveTime(e, item.id)}
                                            display="inline"
                                            isDisabled={!isOnline || item.approval}
                                            size={{ base: "sm", md: "md" }}
                                        />
                                    </Flex>
                                    {/* } */}
                                </VStack>
                            )}
                    </HStack>
                </VStack>
            );
        });
    };

    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            <PanelWrapper title="Hours">
                {ticket && ticketTimeEntries && ticketTimeEntries?.length > 0 ? (
                    <VStack align="stretch" gap={{ base: 2, lg: 4 }} p={2} divider={<StackDivider />}>
                        <VStack width="full" alignItems="stretch" gap={2}>
                            <HStack
                                alignItems="flex-start"
                                fontSize="14px"
                                color="#455360"
                                textAlign="left"
                                gap={{ base: 3, lg: "60px" }}
                                divider={<StackDivider />}
                            >
                                <Text flexBasis={{ base: "50%", md: "30%" }}>Activity</Text>
                                <Text
                                    px={{ base: 2, sm: 5 }}
                                    flexBasis={{ base: "50%", md: "30%" }}
                                >
                                    Total Hours
                                </Text>
                                {role === "driver-lead" &&
                                    (ticket?.status === "submitted" ||
                                        ticket?.status === "unapproved") && (
                                        <Text flexBasis={{ base: "50%", md: "30%" }}>
                                            Approve
                                        </Text>
                                    )}
                            </HStack>
                            <VStack align="stretch">{renderDriverHoursDetail()}</VStack>
                        </VStack>
                        <HStack
                            w={"full"}
                            alignItems="flex-start"
                            gap={"60px"}
                            divider={<StackDivider />}
                        >
                            <DetailTile title="Grand Total" detail={Number(grandTotal).toFixed(2)} />
                        </HStack>
                    </VStack>
                ) : (
                    <Box fontSize="14px" color="#455360">
                        No time entries have been added.
                    </Box>
                )}
            </PanelWrapper>
        </Box>
    );
}