import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
  InputLeftAddon,
  VStack,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { RegisterOptions, useFormContext } from "react-hook-form"
import { KnownIcon } from "../../widgets/KnownIcon"

interface PhoneFieldProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function PhoneField({
  label,
  name,
  registerOptions,
  helper,
}: PhoneFieldProps) {
  const { register, setValue, watch, formState, trigger } = useFormContext();
  const currentValue = watch(name)

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const phoneDigits = value.replace(/\D/g, '')

    // Apply formatting
    let formattedPhone = ''
    if (phoneDigits.length > 0) {
      // First 3 digits
      formattedPhone += phoneDigits.slice(0, 3)

      // Add first hyphen after 3 digits
      if (phoneDigits.length >= 4) {
        formattedPhone += `-${phoneDigits.slice(3, 6)}`
      }

      // Add second hyphen after 6 digits
      if (phoneDigits.length >= 7) {
        formattedPhone += `-${phoneDigits.slice(6, 10)}`
      }
    }

    return formattedPhone
  }

  const validatePhone = (value: string | undefined) => {
    if (value) {
      const isValidPhone = /^\d{3}-\d{3}-\d{4}$/.test(value)
      if (isValidPhone) {
        return undefined // Valid phone number
      }
      return "Invalid phone number format. Please use ###-###-####"
    }
  }

  // Default registration options
  const defaultRegisterOptions = {
    validate: {
      validPhone: validatePhone,
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      // Format the input in real-time
      const formatted = formatPhoneNumber(e.target.value)
      setValue(name, formatted, { shouldValidate: true })
    }
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  //trigger validation intially to check valid format
  useEffect(() => {
    if (currentValue) {
      trigger(name)
    }
  }, [])

  return (
    <FormControl isInvalid={!!formState.errors?.[name]}>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel color="#455360">{label} {registerOptions?.required && <span style={{ color: 'red' }}>*</span>}</FormLabel>
        <InputGroup>
          <InputLeftAddon h={"48px"} border="none">
            <KnownIcon name="phone" />
          </InputLeftAddon>
          <Input
            {...register(name, mergedRegisterOptions)}
            id={name}
            h={"48px"}
            color={"#0E1628"}
            variant="filled"
            inputMode="numeric"
            placeholder="###-###-####"
            maxLength={12} // 3 digits + hyphen + 3 digits + hyphen + 4 digits
          />
        </InputGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>
          {formState.errors?.[name]?.message?.toString()}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  )
}