import BillingToSelect from './BillingToSelect';
import { Mode } from '../../pages/stamp/StampTicketDetail';
import { InputStreak } from '../../components/forms/InputStreak';
import { TicketResource } from '../../pages/user/TicketDetailPage';
import { InputField } from '../../components/forms/fields/InputField';
import { DigitalSignPadField } from '../../components/forms/fields/DigitalSignPadField';
export function StampForm({
    ticket,
    mode = Mode.CodeOnly
}: {
    ticket?: TicketResource | undefined,
    mode?: Mode
}) {
    const contactCustomers = ticket?.customer?.contacts || [];

    return (
        <>
            <InputStreak >
                <InputField name="cost_class" label="AEF# / Cost Class" placeholder='Add Cost Class' />
                <InputField name="minor_class" label="Minor Class" placeholder='Add Minor Class' />
            </InputStreak>
            <InputStreak  >
                <InputField name="major_class" label="Major Class" placeholder='Add Major Class' />
                <InputField name="other" label="Other" placeholder='Additional Details' />
            </InputStreak>
            {
                mode != Mode.Default && (
                    <InputStreak  >
                        <BillingToSelect
                            label="Notify Another Contact for Their Signature"
                            name='billing_customer'
                            contactCustomers={contactCustomers}
                            disabled={mode === Mode.CodeAndStamp}
                        />
                    </InputStreak>
                )
            }
            {
                (mode !== Mode.CodeOnly) && (
                    <InputStreak label="Signature" >
                        <DigitalSignPadField name="digital_sign" />
                    </InputStreak>
                )}
        </>
    )
}