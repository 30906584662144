import at from "/icons/at.svg";
import pdf from "/icons/pdf.svg";
import lock from "/icons/lock.svg";
import lock1 from "/icons/lock1.svg";
import unlock from "/icons/unlock.svg";
import unlock1 from "/icons/unlock1.svg";
import greylock from "/icons/greylock.svg";
import link from "/icons/link.svg";
import home from "/icons/home.svg";
import next from "/icons/next.svg";
import phone from "/icons/phone.svg";
import clock from "/icons/clock.svg";
import pkg from "/icons/package.svg";
import toxic from "/icons/toxic.svg";
import logout from "/icons/logout.svg";
import darkUser from "/icons/user.svg";
import filter from "/icons/filter.svg";
import dollar from "/icons/dollar.svg";
import upload from "/icons/upload.svg";
import driver from "/icons/driver.svg";
import worker from "/icons/worker.svg";
import editIcon from "/icons/edit.svg";
import options from "/icons/options.svg";
import divider from "/icons/divider.svg";
import customer from "/icons/customer.svg";
import mailSent from "/icons/mailsent.svg";
import location from "/icons/location.svg";
import previous from "/icons/previous.svg";
import deleteIcon from "/icons/delete.svg";
import activity from "/icons/activity.svg";
import blueUser from "/icons/blueUser.svg";
import redDollar from "/icons/redDollar.svg";
import lightUser from "/icons/lightuser.svg";
import magnifier from "/icons/magnifier.svg";
import arrowDown from "/icons/arrowDown.svg";
import darkDollar from "/icons/darkDollar.svg";
import blueDollar from "/icons/blueDollar.svg";
import shippingBox from "/icons/shippingBox.svg";
import calendarIcon from "/icons/calendarIcon.svg";
import ascending from "/icons/ascending.svg";
import descending from "/icons/descending.svg";
import subsidiaryClass from "/icons/subsidiaryClass.svg";
import { Image, ImageProps } from "@chakra-ui/react";

// Mapping object
export const iconMappings = {
    darkUser: darkUser,
    blueUser: blueUser,
    lightUser: lightUser,
    lock: lock,
    greylock: greylock,
    lock1: lock1,
    unlock: unlock,
    unlock1: unlock1,
    arrowDown: arrowDown,
    logout: logout,
    calendar: calendarIcon,
    magnifier: magnifier,
    link: link,
    location: location,
    previous: previous,
    next: next,
    home: home,
    divider: divider,
    pdf: pdf,
    mailSent: mailSent,
    filter: filter,
    delete: deleteIcon,
    edit: editIcon,
    clock: clock,
    activity: activity,
    dollar: dollar,
    phone: phone,
    at: at,
    shippingBox: shippingBox,
    subsidiaryClass: subsidiaryClass,
    package: pkg,
    toxic: toxic,
    options: options,
    upload: upload,
    customer: customer,
    driver: driver,
    worker: worker,
    darkDollar: darkDollar,
    blueDollar: blueDollar,
    redDollar: redDollar,
    ascending: ascending,
    descending: descending
}

export type KnownIconName = keyof typeof iconMappings

interface KnownIconProps extends ImageProps {
    name: KnownIconName;
    size?: string | number;
}
export function KnownIcon({ name, color = 'currentColor', ...rest }: KnownIconProps) {
    const component = getIconComponent(name)
    return <Image src={component || ''} {...rest} position={"relative"} color={color} />
}

export function getIconComponent(name: KnownIconName) {
    const component = iconMappings[name]
    if (!component) {
        return null
    }
    return component
}


