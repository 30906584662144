import { TraitSelector, useTrait } from "../../../app/hooks"
import { FormControl, FormLabel, FormHelperText, Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react"

interface ReadonlyFieldProps {
  label?: string
  helper?: string
  isRequired?: boolean
  value?: string | number
  traitId?: string | number
  traitGetter?: TraitSelector;
}

export function ReadonlyField({ traitId, traitGetter, label, helper, isRequired, value }: ReadonlyFieldProps) {
  const result = traitId && traitGetter ? useTrait(
    (entry) => entry.name || `${entry.first_name} ${entry.last_name}`,
    traitId,
    traitGetter,
  ) : undefined;
  const { trait = '', isNew = false } = result || {};

  return (
    <FormControl isRequired={isRequired} cursor={"not-allowed"}>
      <FormLabel color="#455360">{label}</FormLabel>
      <InputGroup >
        <Input id={label} value={traitId ? trait : value} variant="filled" cursor={"not-allowed"} pointerEvents="none" tabIndex={-1} readOnly />
        {
          traitId && isNew &&
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='xs' color={"#3455FF"} bgColor={"white"} cursor='none'>
              New
            </Button>
          </InputRightElement>
        }
      </InputGroup>
      {helper && <FormHelperText color="gray.400">{helper}</FormHelperText>}
    </FormControl>
  )
}