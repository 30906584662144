import { useMemo, useState } from "react";
import { MdCancel } from "react-icons/md";
import { KnownIcon } from "../../widgets/KnownIcon";
import { Box, Flex, Icon, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Skeleton, Spinner, useDisclosure } from "@chakra-ui/react";


interface ImageSkeletonProps {
    attachment: any
    handleRemove?(data: string): void
    showRemoveIcon?: boolean;
}

export const ImageWithSkeleton = ({ attachment, handleRemove, showRemoveIcon = false }: ImageSkeletonProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [removingFileKey, setRemovingFileKey] = useState<boolean>(false);  // state to track the file being removed
    const [modalAttachment, setModalAttachment] = useState<string>('');
    const [isPdf, setIsPdf] = useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClick = (url: string) => {
        setModalAttachment(url);
        attachment?.file_key?.endsWith(".pdf") && setIsPdf(true)
        onOpen();
    };
    const onRemove = (file: string) => {
        setRemovingFileKey(true)
        handleRemove?.(file);
    }
    const modifiedPdfUrl = useMemo(() => {
        return `${modalAttachment}#toolbar=0&view=FitH&scrollbar=0&zoom=100`;
    }, [modalAttachment])

    return (
        <Box position="relative" boxSize={["100px", "120px", "200px"]} alignContent={"center"} border="1px solid #ccc" borderRadius="md">
            {!removingFileKey ? (
                <Skeleton isLoaded={!isLoading} height="100%" width="100%" >
                    {
                        attachment?.file_key?.endsWith(".pdf") ?
                            <Box w="100%" h="100%" display="flex" justifyContent="center" alignItems="center" cursor="pointer" bgColor={"#DFE4FF"}
                                onClick={() => handleClick(attachment?.presigned_url ?? "")}>
                                <KnownIcon name="pdf" boxSize='4rem' onLoad={() => setIsLoading(false)} />
                            </Box>
                            :
                            <Image
                                h="100%"
                                w="100%"
                                src={attachment?.presigned_url ?? ""}
                                alt="Image"
                                objectFit="cover"
                                onLoad={() => setIsLoading(false)}
                                onClick={() => handleClick(attachment?.presigned_url ?? "")}
                                cursor="pointer"
                            />
                    }
                </Skeleton>
            ) : (
                <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(255, 255, 255, 0.8)"
                    border="1px solid #ccc"
                    borderRadius="md"
                    zIndex="10"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spinner size="lg" color="blue.500" />
                </Flex>
            )
            }
            {
                showRemoveIcon &&
                <Icon
                    as={MdCancel}
                    boxSize={6}
                    color="#888"
                    borderRadius="50%"
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    onClick={() => onRemove(attachment?.file_key ?? "")}
                    style={{ display: isLoading ? 'none' : 'block' }}
                    cursor="pointer"
                    _hover={{
                        color: "#3455FF",
                        transform: "scale(1.2)",
                        transition: "transform 0.2s ease-in-out",
                    }}
                    zIndex={10}
                />
            }

            <Modal isCentered isOpen={isOpen} onClose={onClose} size="full">
                <ModalOverlay w="full" bg="rgba(0, 0, 0, 0.75)" />
                <ModalContent className="abcd" bg="none" boxShadow="none" w="100%" h="100%" marginX="auto">
                    <ModalBody position="relative" >
                        <Flex w="full" h="full" alignItems={"center"} justifyContent={"center"}>
                            {
                                !isPdf ?
                                    <Box maxW="80%">
                                        <Image w="100%" src={modalAttachment} alt="Image" objectFit="contain" />
                                    </Box>
                                    :
                                    <Box w="80vw" >
                                        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                                            <iframe
                                                src={modifiedPdfUrl}
                                                style={{ width: '100%', height: '100%', border: 'none', overflow: 'hidden' }}
                                                title="PDF Viewer"

                                            />
                                        </div>
                                    </Box>
                            }
                        </Flex>
                        <ModalCloseButton
                            position="fixed"
                            top="2rem"
                            right="2rem"
                            color="#fff9"
                            _hover={{ transform: "scale(1.2)", transition: "transform 0.2s ease-in-out" }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box >
    );
};
