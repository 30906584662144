import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldProps } from "../../../forms";
import { useFormContext, Controller } from "react-hook-form";
import { KnownIcon } from "../../widgets/KnownIcon";
import { TraitSelector, useTrait } from "../../../app/hooks";
import {
  FormControl, FormLabel, FormHelperText, FormErrorMessage,
  InputGroup, Input, List, ListItem, Box, Text, InputLeftAddon,
  VStack, InputRightElement, Button
} from "@chakra-ui/react";

export interface AutocompleteSuggestion {
  value: number;
  text: string;
}

interface AutocompleteFieldProps extends FieldProps {
  suggestions: AutocompleteSuggestion[];
  onMatch?: (selectedSuggestion: AutocompleteSuggestion) => void;
  traitGetter?: TraitSelector;
  isDisabled?: boolean;
  resetDependentField?: () => void;
}

export function AutocompleteField({
  label,
  name,
  suggestions,
  helper,
  onMatch,
  registerOptions,
  icon,
  placeholder,
  traitGetter,
  isDisabled,
  resetDependentField
}: AutocompleteFieldProps) {
  const { control, formState: { errors }, trigger, setValue, watch } = useFormContext();
  const [nameField, fieldType] = name.split(".");
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Get entity name using the custom hook
  const inputValue = watch(name);
  const result = traitGetter && useTrait((entry) => entry.name, inputValue, traitGetter);
  const { trait = "", isNew = false } = result || {};

  // Memoized filtered suggestions
  const filteredSuggestions = useMemo(() => {
    if (!inputValue?.length) return [];
    return suggestions.filter(({ text }) => text.toLowerCase().includes(inputValue.toLowerCase()));
  }, [inputValue, suggestions]);

  // Select suggestion
  const handleSelectSuggestion = useCallback((suggestion: AutocompleteSuggestion) => {
    setValue(name, suggestion.value);
    onMatch?.(suggestion);
    setShowSuggestions(false);
    trigger(name);
    resetDependentField?.();
  }, [name, onMatch, setValue, trigger, resetDependentField]);

  // Handle input change
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, fieldOnChange: (value: string) => void) => {
    const value = e.target.value;
    fieldOnChange(value); // Update form state
    setShowSuggestions(!!value.trim() && filteredSuggestions.length > 0);
    resetDependentField?.();
  }, [filteredSuggestions.length, resetDependentField]);

  // Handle blur event
  const handleBlur = useCallback(() => {
    const matchedSuggestion = suggestions.find(({ text }) => text === inputValue);
    if (matchedSuggestion) handleSelectSuggestion(matchedSuggestion);

    setTimeout(() => {
      if (filteredSuggestions.length === 1) {
        handleSelectSuggestion(filteredSuggestions[0]);
      } else {
        setShowSuggestions(false);
      }
    }, 300);
    trigger(name);
  }, [inputValue, suggestions, handleSelectSuggestion, trigger, name, filteredSuggestions]);

  // Form validation error
  const errorValue = errors?.[nameField];
  const errorMsg: any = (errorValue as any)?.[fieldType]?.message || "";

  return (
    <FormControl isInvalid={!!(errorValue as any)?.[fieldType] && !isDisabled}>
      <VStack gap="10px" alignItems="flex-start" position="relative">
        <FormLabel color="#455360">
          {label} {registerOptions?.required && <span style={{ color: "red" }}>*</span>}
        </FormLabel>

        <Controller
          name={name}
          control={control}
          rules={registerOptions}
          render={({ field }) => (
            <InputGroup>
              {icon && (
                <InputLeftAddon
                  h="48px"
                  border="none"
                  opacity={isDisabled ? 0.4 : 1}
                  cursor={isDisabled ? "not-allowed" : "auto"}
                >
                  <KnownIcon name={icon} />
                </InputLeftAddon>
              )}

              <Input
                {...field}
                id={name}
                value={trait || field.value || ""}
                h="48px"
                color="#0E1628"
                variant="filled"
                borderRadius="4px"
                placeholder={placeholder}
                onChange={(e) => handleInputChange(e, field.onChange)}
                onBlur={handleBlur}
                isDisabled={isDisabled}
              />

              {isNew && (
                <InputRightElement width="4.5rem" h="48px">
                  <Button h="1.75rem" size="xs" color="#3455FF" bgColor="white" cursor="none">
                    New
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          )}
        />

        {showSuggestions && (
          <Box position="absolute" top="95px" width="100%" zIndex="dropdown">
            <List bg="white" boxShadow="lg">
              {filteredSuggestions.map((suggestion) => (
                <ListItem
                  key={suggestion.value}
                  cursor="pointer"
                  px={4}
                  py={2}
                  _hover={{ bg: "gray.100" }}
                  onClick={() => handleSelectSuggestion(suggestion)}
                >
                  <Text>{suggestion.text}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errorMsg}</FormErrorMessage>
      </VStack>
    </FormControl>
  );
}