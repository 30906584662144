import { useNavigate } from "react-router-dom";
import { KnownIcon } from "./widgets/KnownIcon";
import { DetailHeading } from "./DetailHeading";
import { MainPageHeader } from "./MainPageHeader";
import { BreadcrumbItemType } from "./BreadCrumbs";
import { CustomButton } from "./forms/CustomButton";
import { Draft } from "../features/draft/draftSlice";
import { components } from "../../src/app/api/schema";
import { Link as RouterLink } from "react-router-dom";
import { ChargeWrapperProps } from "./ChargeDetailWrapper";
import { StickyHeader } from "./forms/fields/StickyHeader";
import { useAppSelector, useConfigCheck } from "../app/hooks";
import { TicketResource } from "../pages/user/TicketDetailPage";
import { selectTicketById } from "../features/tickets/ticketsSlice";
import { usePartialUpdateTicketMutation } from "../features/tickets/ticketsApi";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, ButtonGroup, Divider, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";

export type ticketStatusEnum = components["schemas"]["StatusEnum"]

interface ChargePageHeaderProps extends ChargeWrapperProps {
    dataresource?: TicketResource | Draft
}

export function ChargePageHeader({
    isdraft,
    isError,
    isLoading,
    resourceId,
    dataresource,
}: ChargePageHeaderProps) {
    const navigate = useNavigate()
    const { isOnline, userProfile } = useConfigCheck()
    const [isLargerDevice] = useMediaQuery("(min-width: 850px)")
    const [partialUpdateTicket, { isLoading: isUpdating }] = usePartialUpdateTicketMutation()
    const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
    const cancelRef = useRef(null);
    //get ticket data
    const ticketData = useAppSelector((state) => selectTicketById(state, Number(resourceId))) as TicketResource

    //save and approve ticket handler
    const handleSaveAndApprove = async () => {
        try {
            const body = {
                id: Number(resourceId),
                status: ticketData?.digital_signed ? "stamped" : "approved",
            }
            await partialUpdateTicket(body)
            navigate(`/user/tickets/${resourceId}`)
        } catch (error) {
            console.error("failed to approve ticket :", error);
        }
    }
    const handleApproveTicket = () => {
        if (ticketData.chargedetails.length === 0) {
            setIsDiscardDialogOpen(true)
        }
    }

    const handleConfirmDiscard = () => {
        handleSaveAndApprove()
        setIsDiscardDialogOpen(false)
    }

    const handleCancelDiscard = () => {
        setIsDiscardDialogOpen(false)
    }

    //on save navigation handler
    const handleSave = () => {
        if (isdraft) {
            navigate(`/user/draft/${(Number(resourceId) || 0) + 1}`)
        } else {
            navigate(`/user/tickets/${resourceId}`)
        }
    }

    //print href based on draft or ticket
    const printWithChargehref = isdraft ? `/print/draft/${(Number(resourceId) || 0) + 1}?withCharge=${true}` : `/print/tickets/${resourceId}?withCharge=${true}`
    const printWithOutChargehref = isdraft ? `/print/draft/${(Number(resourceId) || 0) + 1}?withCharge=${false}` : `/print/tickets/${resourceId}?withCharge=${false}`

    //diable button based on network connectivity and draft or ticket
    const isButtonDisabled = !(isOnline || isdraft);

    //bread crumbs for charges page
    const breadCrumbItems: BreadcrumbItemType[] = isdraft
        ? [
            { label: 'Home', path: '/user/home', icon: 'home' },
            { label: dataresource?.number ? `#${dataresource?.number}` : 'Create New Ticket', path: `/user/draft/${(Number(resourceId) || 0) + 1}` },
            { label: 'All Charges', isCurrentPage: true },
        ]
        : [
            { label: 'Home', path: '/user/home', icon: 'home' },
            ...(!isError ? [
                { label: 'View All Tickets', path: '/user/tickets' },
                { label: `#${dataresource?.number || ''} `, path: `/user/tickets/${resourceId}` },
                { label: 'All Charges', isCurrentPage: true },
            ] : [])
        ];

    const title = !isdraft ? `Charges for #${dataresource?.number}` : 'Add charge to draft'
    return (
        <>
            <MainPageHeader isLoading={isLoading} breadCrumbItems={breadCrumbItems} />
            <StickyHeader>
                <VStack w={"full"} gap={0} alignItems={"flex-start"}>
                    <DetailHeading title={title} fontSize={{ base: "1rem", lg: "1.5rem" }} />
                    {isdraft && <Text fontWeight={400} fontSize={{ base: "12px", lg: "14px" }} color={"#737791"}>This ticket has not yet been saved to the cloud</Text>}
                </VStack>
                {
                    isLargerDevice ?
                        <ButtonGroup gap={2}>
                            <CustomButton title="Print with" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithChargehref} isDisabled={isButtonDisabled} target={"_blank"} />
                            <CustomButton title="Print without" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithChargehref} isDisabled={isButtonDisabled} target={"_blank"} />
                            {userProfile?.role === "driver-lead" && (ticketData?.status === "submitted" || ticketData?.status === "unapproved") &&
                                <CustomButton title="Save and Approve" onClick={
                                    () => {
                                        if (ticketData?.chargedetails.length > 0) {
                                            handleSaveAndApprove()
                                        }
                                        handleApproveTicket()
                                    }
                                } isLoading={isUpdating} isDisabled={isButtonDisabled} />
                            }
                            <CustomButton title="Save" onClick={handleSave} />
                        </ButtonGroup> :
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<KnownIcon name='options' boxSize="24px" />}
                                aria-label='Options'
                                background="transparent"
                                isDisabled={isButtonDisabled}
                            />
                            <MenuList color={"#455360"}>
                                <MenuItem as={RouterLink} to={printWithChargehref} isDisabled={isButtonDisabled} target={"_blank"}  >
                                    <HStack alignItems={'center'}>
                                        <Text>Print With</Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                <Divider bgColor={"#F0F0F0"} />
                                <MenuItem as={RouterLink} to={printWithOutChargehref} isDisabled={isButtonDisabled} target={"_blank"} >
                                    <HStack alignItems={'center'}>
                                        <Text>Print Without</Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                {
                                    userProfile?.role === "driver-lead" && (ticketData?.status === "submitted" || ticketData?.status === "unapproved")
                                    &&
                                    <>
                                        <Divider bgColor={"#F0F0F0"} />
                                        <MenuItem onClick={() => {
                                            if (ticketData?.chargedetails.length > 0) {
                                                handleSaveAndApprove()
                                            }
                                            handleApproveTicket()
                                        }} isDisabled={isButtonDisabled}>
                                            Save and Approve
                                        </MenuItem>
                                    </>
                                }
                                <Divider bgColor={"#F0F0F0"} />
                                <MenuItem onClick={handleSave} isDisabled={isButtonDisabled}>
                                    Save
                                </MenuItem>
                            </MenuList>
                        </Menu>
                }
                <AlertDialog
                    isOpen={isDiscardDialogOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={handleCancelDiscard}
                    size={["xs", "sm"]}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Approve Ticket
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                This ticket does not contain any charges to the customer.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <ButtonGroup>
                                    <CustomButton title="Cancel" onClick={handleCancelDiscard} />
                                    <CustomButton title="Approve Anyway" variant="outline" colorScheme="red" onClick={handleConfirmDiscard} />
                                </ButtonGroup>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </StickyHeader>
        </>
    )
}
